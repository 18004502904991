import React, { Component } from 'react';
import { Redirect } from 'react-router';
import PageWrapper from '../components/PageWrapper';
import { Loader, Input, DateTimePicker, Icon, ContentCard, DropdownList } from 'filament-ui';
import LoaderRing from '../images/loader.svg';
import axios from 'axios';
import moment from 'moment';
import { StickyContainer, Sticky } from 'react-sticky';

import { API_ROOT } from '../apiConfig';
import AgendaList from '../components/AgendaList';

export default class Course extends Component {

  constructor(props) {
    super(props);

    this.viewSection = this.viewSection.bind(this);
    this.cancel = this.cancel.bind(this);
    this.viewClient = this.viewClient.bind(this);
    this.updateDates = this.updateDates.bind(this);
    this.getClient = this.getClient.bind(this);
    this.modulesUpdated = this.modulesUpdated.bind(this);
    this.toggleRelated = this.toggleRelated.bind(this);
    this.addRelatedModule = this.addRelatedModule.bind(this);
    this.relatedModuleUpdated = this.relatedModuleUpdated.bind(this);
    this.updateRelatedList = this.updateRelatedList.bind(this);
    this.updateDates = this.updateDates.bind(this);
    this.updateList = this.updateList.bind(this);
    this.saveCourse = this.saveCourse.bind(this);
    this.updateTimes = this.updateTimes.bind(this);
    this.toggleActions = this.toggleActions.bind(this);

    this.setActiveTab = this.setActiveTab.bind(this);
    this.addElement = this.addElement.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.typeChange = this.typeChange.bind(this);
    this.durationChange = this.durationChange.bind(this);

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);

    this.state = this.getInitialState();

  }

  componentDidMount () { 
    this.getClient();
  }

  getInitialState() {

    const initialState = { 
      loading : true,
      showToast : false,

      modules : [], 
      course_name : "",
      course_dates : [ new Date(), new Date() ],
      activeTabIndex : 0,
      activeTab: 'Overview',
      subHeaderRoutes : {
        tabs: [ 
          {
            name: 'Overview', callback: this.setActiveTab
          },
          {
            name: 'Wireframe', callback: this.setActiveTab
          },
          {
            name: 'Summary', callback: this.setActiveTab
          } 
        ]
      },
      timeslotTypes : [],
      courseWireframeOrder : [
        
      ]
    };

    return initialState;
  }

  inputChange ( value, index ) { 
    var tmp = this.state.courseWireframeOrder;
    tmp[index].inputs[0].value = value;

    if ( tmp[index].type === 5 ) { 
      // only for modules... 
      // need to check if this matches any of the modules example test.. 
      for ( var i = 0; i < this.state.modules.length; i++ )  { 
        var mod = this.state.modules[i];
        // check against the course title first... 
        var found = false;
        if ( mod.name != null ) { 
          if ( value.match(new RegExp(mod.name, "i")) != null ) { 
            // found a match.. 
            tmp[index].module = mod.name;
            tmp[index].moduleObject = mod;
            found = true;
          }
        }
        if ( !found && mod.keyword_csv != null ) { 
          var arr = JSON.parse(mod.keyword_csv);
          for ( var r = 0; r < arr.length; r++ ) { 
            if ( value.match(new RegExp(arr[r].text, "i")) != null ) { 
              // found a match.. 
              tmp[index].module = mod.name;
              tmp[index].moduleObject = mod;
              found = true;
              break;
            }
          } 
        }
        if ( found ) { 
          break;
        }
      }
    } else { 
      // ensure nothing is set.
      tmp[index].module = null;
      tmp[index].moduleObject = null
    }
    
    this.setState ( { 
      courseWireframeOrder : tmp
    } ); // save the new value... 
  }

  typeChange ( value, index ) { 
    var tmp = this.state.courseWireframeOrder;
    tmp[index].type = value.id;

    // need to make sure the module is reset when type changes...
    tmp[index].module = null;
    tmp[index].module_id = null;
    tmp[index].moduleObject = null;

    var findModule = function ( modules, id ) { 
      for ( var i = 0; i < modules.length; i++ ) { 
        if ( modules[i].id === id ) { 
          return modules[i];
        }
      }
      return "";
    }

    var wireframetype = null;

    for ( var t = 0; t < this.state.timeslotTypes.length; t++ ) { 
      if ( this.state.timeslotTypes[t].id === value.id ) { 
        wireframetype = this.state.timeslotTypes[t];
        break;
      }
    }

    // we know the type, and it has a module associated with it. 
    if ( wireframetype && wireframetype.mid ) { 
      // get the module with that id... 
      var thismodule = findModule ( this.state.modules, wireframetype.mid );  
      tmp[index].module = thismodule.name;
      tmp[index].module_id = thismodule.id;
      tmp[index].moduleObject = thismodule;
    }

    // if ( this.state.timeslotTypes[value.id].mid != null ) {
    //   for ( var i = 0; i < this.state.modules.length; i++ )  { 
    //     if ( this.state.modules[i].id === this.state.timeslotTypes[value.id].mid ) { 
    //       console.log("setting module to be = ", this.state.modules[i].name )
    //       tmp[index].module = this.state.modules[i].name;
    //       tmp[index].moduleObject = this.state.modules[i];
    //       break;
    //     }
    //   }
    // }

    tmp = this.updateTimes ( tmp );   

    this.setState ( { 
      courseWireframeOrder : tmp
    } ); // save the new value... 
  }

  durationChange ( value, index ) { 
    var tmp = this.state.courseWireframeOrder;
    if ( value ) { 
      tmp[index].duration = value;
    }

    tmp = this.updateTimes ( tmp );   

    this.setState ( { 
      courseWireframeOrder : tmp
    } ); // save the new value... 
  }

  updateTimes ( tmp ) { 
    // also need to adjust all the times in the agenda... 
    var times = this.state.course_dates;
    var start = times[0].getTime();
    var nextStart = moment(start);
    var newDays = 0;
    for ( var i = 0; i < tmp.length; i++ ) { 

      if ( tmp[i].type === 6 ) { 
        // new day 
        if ( i !== 0 ) { 
          newDays++;
          nextStart = moment(start).add(newDays, "days");
        }
        start = moment(nextStart.format("YYYY-MM-DD") + " " + 
          ( tmp[i].startTime != null ? tmp[i].startTime : 
            ( tmp[i].start != null ? tmp[i].start : "" )  
          ) 
        ).toDate().getTime()
        nextStart = moment(start); 
      } else if ( i > 0 && tmp[i-1].type !== 6 ) { 
        nextStart = nextStart.add(tmp[i-1].duration, "minutes"); 
      } else { 
        
      }
      // set the start... 
      if ( nextStart._isValid ) { 
        tmp[i].start = nextStart.format("HH:mm");
      }
      tmp[i].day = ( newDays + 1 );
    }
    return tmp;
  }

  next() { 
    var index = this.state.activeTabIndex + 1;
    var newTab = this.state.subHeaderRoutes.tabs[index];
    this.setState({activeTab: newTab.name, activeTabIndex : index });
  }

  previous () { 
    var index = this.state.activeTabIndex - 1;
    var newTab = this.state.subHeaderRoutes.tabs[index];
    this.setState({activeTab: newTab.name, activeTabIndex : index });
  }

  setActiveTab(tab, index) {
    this.setState({activeTab: tab.name, activeTabIndex : index });
  }

  async getClient () { 
    var _this = this;

    var wireframeTypes = await axios.get(API_ROOT + "/db/wireframe_types");
    var types = wireframeTypes.data.documents;
    this.setState( { 
      timeslotTypes: types
    }, () => { 

      var clientId = this.props.match.params.id;
      return axios.get(`${API_ROOT}/db/clients/` + clientId, {
      }).then((resp) => {
        return axios.get(`${API_ROOT}/db/modules`, {
        }).then((modresp) => {
          var modules = modresp.data.documents;
          if ( this.props.match.params.course_id && !isNaN(this.props.match.params.course_id) ) { 
            // editing previous course..
            return axios.get(`${API_ROOT}/db/course/` + this.props.match.params.course_id, {
            }).then((courseresp) => {
              var courseData = courseresp.data;

              var findModule = function ( modules, id ) { 
                for ( var i = 0; i < modules.length; i++ ) { 
                  if ( modules[i].id === id ) { 
                    return modules[i];
                  }
                }
                return "";
              }

              this.setState( { 

                timeslotTypes: types,

                client : resp.data, 
                modules : modules, 
                loading: false ,
                course_id : courseData.id,
                course_name : courseData.name, 
                course_dates : [ new Date(courseData.start_date), new Date(courseData.end_date) ],
                courseWireframeOrder: ( courseData.wireframes ? courseData.wireframes.map ( ( wireframe, index ) => { 
                  return { 
                    key: '_' + Math.random().toString(36).substr(2, 9),
                    start : wireframe.start,
                    duration: wireframe.duration,
                    durationUpdated : this.durationChange,
                    type : wireframe.type,
                    typeUpdated: this.typeChange,
                    types : types,
                    related : wireframe.related.length > 0, 
                    relatedModules : wireframe.related.map ( ( module, mi ) => { 
                      return { 
                        key : '_' + Math.random().toString(36).substr(2, 9),
                        name : module.module.name,
                        module: module.module
                      }
                    }),
                    module : findModule(modules, wireframe.module_id).name,
                    moduleObject : findModule(modules, wireframe.module_id),
                    moduleUpdated : this.modulesUpdated,
                    toggleRelated : this.toggleRelated,
                    addRelatedModule : this.addRelatedModule,
                    relatedModuleUpdated : this.relatedModuleUpdated,
                    inputs : [ 
                      {
                        value: wireframe.description,
                        callback : this.inputChange
                      }
                    ]
                  }
                } ) : [] )
              }, () => { 
                //console.log("wireframes = ", this.state.courseWireframeOrder );
              });  
            });
          } else { 
            _this.setState( { client : resp.data, modules : modules, loading: false } );
          }
        })
      });

    } );
  }

  updateDates(dates) {
    this.setState({ course_dates : dates } );
  }

  modulesUpdated(selected, index, item ) {
    var tmp = this.state.courseWireframeOrder;
    tmp[index].module = selected.name;
    tmp[index].moduleObject = selected;

    this.setState ( { courseWireframeOrder : tmp } );
  }

  toggleRelated ( selected, index  ) { 
    var tmp = this.state.courseWireframeOrder;
    tmp[index].module = selected.name;
    tmp[index].moduleObject = selected;
    if ( tmp[index].related ) { 
      tmp[index].related = !tmp[index].related;
    } else { 
      tmp[index].related = true;
    }
    tmp[index].relatedModules = []; // if we start it, or if we toggle it off, it should always be set to empty.
    this.setState ( { courseWireframeOrder : tmp } );
  }

  addRelatedModule ( item, index ) { 
    var tmp = this.state.courseWireframeOrder;
    tmp[index].relatedModules.push ( { 
      key: '_' + Math.random().toString(36).substr(2, 9)
    });

    this.setState ( { courseWireframeOrder : tmp } );
  }

  relatedModuleUpdated(selected, rindex, index ) {
    var tmp = this.state.courseWireframeOrder;
    tmp[index].relatedModules[rindex].name = selected.name;
    tmp[index].relatedModules[rindex].module = selected;

    this.setState ( { courseWireframeOrder : tmp } );
  }

  updateList(newList) {
    var tmp = this.updateTimes(newList);
    this.setState({ courseWireframeOrder: tmp});
  }

  updateRelatedList ( index, newList ) { 
    var tmp = this.state.courseWireframeOrder;
    tmp[index].related = newList;
    this.setState ( { courseWireframeOrder : tmp } );
  }

  viewSection(redirect) {
    this.setState({ redirect });
  }

  viewClient( clientId ) {
    this.viewSection("/client/" + clientId);
  }

  cancel () { 
    this.viewClient(this.props.match.params.id);
  }

  saveCourse ( props ) { 
    this.setState( { loading : true } );
    
    var course = { 
      "name" : props.course_name,
      "client_id" : this.props.match.params.id, 
      "start_date" : props.course_dates[0].getTime(),
      "end_date" : props.course_dates[1].getTime(),
      "wireframes" : props.courseWireframeOrder.map ( ( wireframe, index ) => { 
        return {
          start: wireframe.start, 
          duration: wireframe.duration,
          type: wireframe.type,
          module_id : wireframe.moduleObject ? wireframe.moduleObject.id : null,
          relatedModules : ( wireframe.relatedModules ? wireframe.relatedModules.map ( ( module ) => { 
            return module.module.id;
          }) : [] ),
          description: wireframe.inputs[0].value
        }
      } )
    }
    if ( props.course_id ) { 
      // edit.. 
      return axios.put(`${API_ROOT}/db/course/` + props.course_id, course, {
      }).then((resp) => {
        this.setState( { loading : false } );
        this.viewClient(this.props.match.params.id);
      });
    } else { 
      // new
      return axios.post(`${API_ROOT}/db/course`, course, {
      }).then((resp) => {
        this.setState( { loading : false } );
        this.viewClient(this.props.match.params.id);
      });
    }
  }

  addElement ( element ) { 
    var tmp = this.state.courseWireframeOrder;
    tmp.push({ 
      key: '_' + Math.random().toString(36).substr(2, 9),
      start :  null,
      duration: 30,
      durationUpdated : this.durationChange,
      type : element.data.id,
      typeUpdated: this.typeChange,
      types : this.state.timeslotTypes,
      module : "",
      moduleUpdated : this.modulesUpdated,
      toggleRelated : this.toggleRelated,
      addRelatedModule : this.addRelatedModule,
      relatedModuleUpdated : this.relatedModuleUpdated,
      inputs : [ 
        {
          callback : this.inputChange
        }
      ]
    });

    tmp = this.updateTimes ( tmp );   

    this.setState({ courseWireframeOrder : tmp }); // add the new one at the end of the list...
  }

  isValid ( props ) { 
    if ( props.course_name == null ) { return false; }
    if ( props.course_dates.length !== 2 ) { return false; }
    if ( props.courseWireframeOrder.length === 0 ) { return false; }
    for ( var i = 0; i < props.courseWireframeOrder.length; i++ ) { 
      // if its a module entry, and doesn't have a module associated with it...
      if ( props.courseWireframeOrder[i].type === 5 && props.courseWireframeOrder[i].module === "" ) { 
        return false;
      }
      if ( props.courseWireframeOrder[i].start === null ) {  // no valid times.
        return false;
      }
    }
    return true;
  }

  toggleActions() {
    this.setState({showDropdown: !this.state.showDropdown});
  }

  getItemType ( item, typeId ) { 
    for ( var i = 0; i < item.types.length; i++ ) { 
        if ( item.types[i] != null && item.types[i].id === typeId ) { 
            return item.types[i];
        }
    }
  }


  render() {
    let { subHeaderRoutes, redirect, loading, activeTab } = this.state;

    if (redirect) {
      return <Redirect push to={redirect} />;
    }

    return (
      <PageWrapper headerBar={ true } search={ true } sideMenu={ true } subHeader={ subHeaderRoutes }>
        {(loading) ? <Loader type={ LoaderRing } size="small"></Loader> : null}

        {(!loading) ?
          <div className="">
            <div className="">
              { activeTab === 'Overview' ? 
                <ContentCard>
                  <h3>Basic Information</h3>
                  <Input
                    label="Company Name"
                    type="text"
                    placeholder="Company"
                    disabled={ true }
                    value={ this.state.client.name }>
                  </Input>

                  <Input
                    label="Workshop Title"
                    type="text"
                    placeholder="A short name for this workshop"
                    inputId="name"
                    helpMessage="This is used to differentiate between workshops for the same client."
                    value={ this.state.course_name }
                    error={ this.state.course_name === "" }
                    onChange={ (e) => this.setState({course_name: e.target.value}) }>
                  </Input>

                  <DateTimePicker 
                    className={ 'w-100' } 
                    label="Workshop Date" 
                    range={ true }
                    time={ false }
                    callback={ this.updateDates } 
                    dates={ this.state.course_dates }>
                  </DateTimePicker>
                </ContentCard>
              : null }


              { this.state.activeTab === 'Wireframe' ? 
                <div>
                  <h3>Wireframe</h3>
                  <div className="flex">
                    <div className="child w-95">
                      <AgendaList
                        dates={ this.state.course_dates }
                        list={ this.state.courseWireframeOrder }
                        modules={ this.state.modules }
                        changeCallback={ this.updateList }
                        changeRelatedCallback={ this.updateRelatedList }>
                      </AgendaList>
                    </div>

                    <StickyContainer className="child w-5 ml2">
                      <div className="flex">
                        <div className="child my1">
                          <Sticky
                            topOffset={20}
                            >
                            {({ style, isSticky }) => (
                              <div style={style} 
                                className={(isSticky ? 'is-sticky' : 'not-sticky') + " dropdown-wrapper"}
                                >
                                <div className="button primary action" 
                                  style={ { padding: "0px" } }
                                  onClick={ () => this.toggleActions() }>
                                  <Icon className="mx1" icon="layer-plus" />
                                </div>
                                {((this.state.showDropdown) ? (
                                  <DropdownList 
                                    className="right" 
                                    list={ this.state.timeslotTypes.map ( (item) => { 
                                      return {
                                        name: item.name,
                                        action: this.addElement, 
                                        data: item
                                      }
                                    } ) } 
                                    parentAction={ this.toggleActions }>
                                  </DropdownList>
                                ) : null)}
                              </div>
                            )}
                          </Sticky>
                        </div>
                      </div>
                    </StickyContainer>
                  </div>
                </div>
              : null }

              { this.state.activeTab === 'Summary' ? 
                <ContentCard>
                  <h3>Agenda</h3>
                  <div className="my1">
                    <div className="flex">
                      <div className="child w-20"><b>Time</b></div>
                      <div className="child w-80"><b>Activity</b></div>
                    </div>
                    { this.state.courseWireframeOrder.map ( (item, index ) => ( 
                      
                        <div key={index} style={ { "display" : ( this.getItemType ( item, item.type ).inAgenda === 1 ? "block" : "none" ) } } >
                          { item.type !== 6 ? // day break... 
                          <div className="flex py1" style={ { "borderBottom" : "1px solid #c0c0c0" } }>
                            <div className="child w-20">{ item.start }</div>
                            <div className="child w-80">
                              { item.inputs[0].value ? item.inputs[0].value : "<No Description>" }
                              { item.type === 5 && item.module ? 
                                <span className="ml1">
                                  ( Workbook : { item.module } )
                                </span>
                              : null }
                              { item.type === 5 && !item.module ? 
                                <span className="ml1 error">
                                  ( Workbook MISSING )
                                </span>
                              : null }
                            </div>
                          </div>  
                          : 
                          <div className="flex my0 mt2 pb1" style={ { "borderBottom" : "2px solid #000000" } }>
                            <div className="child w-20"></div>
                            <div className="child w-80">
                              { item.inputs[0].value ? item.inputs[0].value : "<No Description>" }
                            </div>
                          </div> 
                          }
                        </div>

                    ) ) }
                  </div>
                </ContentCard>
              : null }

            </div>
          </div>
        : null }

        {(!loading ) ?
          <div className="w-100 flex flex-afe flex-jfe mt3">
            <button onClick={ () => this.cancel() } className="button primary inverted px2 mr1">Cancel</button>

              <button className="button primary px2 mr1 " 
                disabled={ this.state.activeTabIndex === 0 } 
                onClick={ () => this.previous() }>
                  <Icon icon="chevron-left" className="mr1"></Icon>Previous
                </button>

              { this.state.activeTabIndex !== 2 ? 
              <button className="button primary px2 mr1" 
                disabled={ this.state.activeTabIndex === 2 || this.state.course_name.length === 0 || this.state.course_dates.length === 0 } 
                onClick={ () => this.next() }>
                  Next<Icon icon="chevron-right" className="ml1"></Icon>
                </button>
                :
              <button className="button primary px2" 
                disabled={ !this.isValid(this.state) } 
                onClick={ () => this.saveCourse(this.state) }>Save</button>
              }
          </div>
        : null }

      </PageWrapper>
    )
  }
}
