import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Input, ToastNotification } from 'filament-ui';
import axios from 'axios';
import { RESET_PASSWORD } from '../apiConfig';

export default class ResetPassword extends Component {

  constructor(props) {
    super(props);

    this.state = {
      password: '',
      passwordConfirm: '',
      showError: false,
      toastMessage: null,
      id: '',
      token: ''
    };

    this.displayToastMessage = this.displayToastMessage.bind(this);
  }

  componentWillMount() {
    this.setState({
      id: this.props.match.params.id,
      token: this.props.match.params.token
    });
  }

  submitForm(event) {
    event.preventDefault();

    const endpoint = `${RESET_PASSWORD}/` + this.state.id + '/' + this.state.token;

    const payload = {
      password: this.state.password
    };

    axios.post(endpoint, payload).then((resp) => {
      if (resp.status === 200) {
        this.displayToastMessage({type: 'success', message: 'Your password has been successfully changed. You can now go back and log in.'});
      }
    }).catch((err) => {
      this.displayToastMessage({type: 'error', message: err.message});
    });
  }

  displayToastMessage(message) {
    this.setState({toastMessage: message});
  }

  render() {
    let { password, passwordConfirm, showError } = this.state;

    return (
      <div className="page-height bg-white flex-centred w-100">
        <header className="w-55 w-85-tab">
          <Link to="/">← Back to Login</Link>
          <h4 className="mt3">Reset Password</h4>
          <p className="accent-dark small">
            Enter and confirm your desired password to update it for your account.
          </p>

          <form className="my2">
            <Input
              type="password"
              placeholder="Enter your password..."
              inputId="password"
              label="Password"
              error={ password.length > 0 && password.length < 8 }
              errorMessage="Please make sure your password is at least 8 characters long."
              value={ password }
              onChange={ (e) => this.setState({password: e.target.value}) }>
            </Input>

            <Input
              type="password"
              placeholder="Confirm your password..."
              inputId="passwordConfirm"
              label="Password Confirmation"
              error={ passwordConfirm.length > 0 && passwordConfirm !== password }
              errorMessage="Please make sure both password fields match."
              value={ passwordConfirm }
              onChange={ (e) => this.setState({passwordConfirm: e.target.value}) }>
            </Input>

            <button className="primary w-100 t-mt2"
              disabled={ password.length === 0  || password !== passwordConfirm || showError}
              onClick={ (e) => this.submitForm(e) }>
              Reset
            </button>
          </form>
        </header>

        {(this.state.toastMessage) ? (
          <ToastNotification type={ this.state.toastMessage.type } message={ this.state.toastMessage.message } onClick={ () => this.displayToastMessage(null) }></ToastNotification>
        ) : null}
      </div>
    )
  }
}
