import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { USER } from '../apiConfig';
import { setCurrentUser } from '../actions/auth';
import PageWrapper from '../components/PageWrapper';
import { ContentCard, Input, ToastNotification } from 'filament-ui';

class Profile extends Component {

  constructor(props) {
    super(props);

    this.state = {
      toastMessage: null,
      profile: {
        location: (this.props.currentUser && this.props.currentUser.location) ? this.props.currentUser.location : '',
        biography: (this.props.currentUser && this.props.currentUser.biography) ? this.props.currentUser.biography : ''
      }
    };

    this.displayToastMessage = this.displayToastMessage.bind(this);
  }

  displayToastMessage(message) {
    this.setState({toastMessage: message});
  }

  saveProfile(e) {
    e.preventDefault();

    axios.put(USER, this.state.profile).then((data) => {
      if (data.status === 200) {
        axios.get(`${USER}/` + this.props.currentUser.id).then((resp) => {
          this.props.setCurrentUser(resp.data);

          this.setState({
            toastMessage: {type: 'success', message: 'Your profile has successfully been updated.'}
          });
        });
      }
    }).catch((err) => {
      this.setState({
        toastMessage: {type: 'error', message: err.message}
      });
    });
  }

  setInputState(field, value) {
    let updatedProp = { ...this.state.profile };
    updatedProp[field] = value;
    this.setState({profile: updatedProp});
  }

  render() {
    let { currentUser } = this.props;

    return (
      <PageWrapper headerBar={ true } search={ true } sideMenu={ true }>
        {(currentUser) ? (
          <div className="mt1 flex flex-ac flex-jsb">
            <ContentCard className="w-39 flex-ass">
              <div className="icon-button flex-centred no-margin action gradient">
                <span>
                  { currentUser.first_name.charAt(0).toUpperCase() + currentUser.last_name.charAt(0).toUpperCase() }
                </span>
              </div>
              <h3 className="centred">{ currentUser.first_name + ' ' + currentUser.last_name }</h3>
              <p className="centred accent-dark">{ currentUser.is_admin ? 'Administrator' : 'Standard User' }</p>

              <p className="mt2"><strong>Details</strong></p>
              <p>{ currentUser.email }</p>
            </ContentCard>

            <ContentCard className="w-59 flex-ass" header="Edit Details">
              <form>
                <Input
                  type="text"
                  placeholder="Location"
                  label="Location"
                  inputId="location"
                  value={ this.state.profile.location }
                  onChange={ (e) => this.setInputState('location', e.target.value) } >
                </Input>

                <Input
                  type="text"
                  placeholder="Biography"
                  label="Biography"
                  inputId="biography"
                  value={ this.state.profile.biography }
                  onChange={ (e) => this.setInputState('biography', e.target.value) } >
                </Input>

                <button
                  className="primary mb1"
                  onClick={ (e) => this.saveProfile(e) }>
                  Save Profile
                </button>
              </form>
            </ContentCard>
          </div>
        ) : null}

        {(this.state.toastMessage) ? (
          <ToastNotification
            type={ this.state.toastMessage.type }
            message={ this.state.toastMessage.message }
            onClick={ () => this.displayToastMessage(null) }>
          </ToastNotification>
        ) : null}
      </PageWrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.login.currentUser
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentUser: (currentUser) => dispatch(setCurrentUser(currentUser))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
