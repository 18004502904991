import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ContentCard, Input, Label, SelectDropdown, Tags, Icon } from 'filament-ui';
import DateTimePicker from '../components/DateTimePicker';
import ReactDragList from 'react-drag-list';

export default class AgendaList extends Component {

    constructor (props) {
        super(props);
        this.durationUpdate = this.durationUpdate.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.deleteRelatedItem = this.deleteRelatedItem.bind(this);
        this.getTags = this.getTags.bind(this);
    }

    handleUpdate = (evt, updated) => {
        const { changeCallback } = this.props;
    	evt.preventDefault();
    	if (changeCallback) {
    		changeCallback(updated);
    	}
    }

    handleRelatedModuleUpdate = (evt, updated, index ) => {
        const { changeRelatedCallback } = this.props;
    	evt.preventDefault();
    	if (changeRelatedCallback) {
    		changeRelatedCallback(index, updated);
    	}
    }

    durationUpdate ( evt, index ) { 
        this.props.list[index].duration = evt.target.value;
    }

    deleteItem ( index ) { 
        const { list, changeCallback } = this.props;
        list.splice(index, 1);
        changeCallback(list);
    }

    deleteRelatedItem ( rindex, index ) { 
        const { list, changeRelatedCallback } = this.props;
        list[index].relatedModules.splice(rindex, 1);
        changeRelatedCallback(index, list);
    }

    getTags ( mod ) { 
        if ( !mod ) { 
            return [];
        } else { 
            for ( var i = 0; i < this.props.modules.length; i++ ) { 
                if ( this.props.modules[i].id === mod.id ) { 
                    if ( this.props.modules[i].keyword_csv ) { 
                        return JSON.parse(this.props.modules[i].keyword_csv);
                    } else { 
                        return [];
                    }
                }
            }
        }
        return [];
    }

    updateDates ( item, value ) { 
        item.startTime = value;
        item.durationUpdated();
    }

    getItemType ( item, typeId ) { 
        for ( var i = 0; i < item.types.length; i++ ) { 
            if ( item.types[i] != null && item.types[i].id === typeId ) { 
                return item.types[i];
            }
        }
        return { }; // empty object just to ensure something is returned.   
    }

    render() {
        const { list } = this.props;

    	return (
    		<div className="prioritised-list">
                { list.length > 0 ? 
    			<ReactDragList
    				dataSource={ list }
    				rowKey="key"
    				row={(item, index) => (
    					<ContentCard key={ item.key } noPadding={ true } className="mb1">
    						<div className="flex flex-ac flex-jsb px1">
    							<div className="flex flex-ac w-100">
                                    <div className="flex w-100 mr0"
                                        style={ { position : 'relative' } }
                                        >
                                        <h3 className="mr1 child w-20">
                                            { item.start } { ( item.type === 6 && item.day ) ? "Day " + item.day : "" }
                                            <div className="child w-100 timeslotTypes">
                                                { this.getItemType ( item, item.type ).showDuration ?
                                                <div>
                                                    <div className="flex flex-jsb">
                                                        <Input
                                                            key={ item.key }
                                                            className={ 'child w-100 '}
                                                            label={ "Duration ( minutes )" }
                                                            type={ 'text' }
                                                            placeholder={ "Enter a duration in minutes." }
                                                            value={ item.duration }
                                                            onChange={ (e) => { item.durationUpdated(e.target.value, index) } }>
                                                        </Input>
                                                    </div>
                                                    <div className="flex w-100 flex-jc flex-wrap mb1">
                                                        <button className={ "child mr1     button w-20 timeButton " + ( item.duration === 15 ? "primary" : "secondary inverted" ) } 
                                                            onClick={ () => { item.durationUpdated(15, index) } }>15</button>
                                                        <button className={ "child mr1     button w-20 timeButton " + ( item.duration === 30 ? "primary" : "secondary inverted" ) } 
                                                            onClick={ () => { item.durationUpdated(30, index) } }>30</button>
                                                        <button className={ "child mr1     button w-20 timeButton " + ( item.duration === 45 ? "primary" : "secondary inverted" ) } 
                                                            onClick={ () => { item.durationUpdated(45, index) } }>45</button>
                                                        <button className={ "child         button w-20 timeButton " + ( item.duration === 60 ? "primary" : "secondary inverted" ) } 
                                                            onClick={ () => { item.durationUpdated(60, index) } }>60</button>
                                                    </div>
                                                </div>
                                                : null }

                                                { item.type === 6 ?
                                                <div>
                                                    <div className="flex flex-jsb mt1">
                                                        <DateTimePicker 
                                                            className={ 'w-100' } 
                                                            label="Todays Start Time" 
                                                            range={ false }
                                                            time={ true }
                                                            callback={ (value) => { this.updateDates(item, value) } } 
                                                            dates={ this.props.dates[0] }>
                                                        </DateTimePicker>
                                                    </div>
                                                </div>
                                                : null }
                                            </div>
                                            <div style={ { position : 'absolute', bottom: '10px', left : '0px' } }>
                                                <button className={ "timeButton primary inverted" } onClick={ (e) => { this.deleteItem(index); } }>
                                                    <Icon icon="trash" type={ 'solid' } className="mx1"></Icon>
                                                </button>
                                            </div>
                                        </h3>
                                        <div className='child w-80'>
                                            <div className="mt1">
                                                <Label 
                                                    className={ this.getItemType ( item, item.type ).class } 
                                                    label={ { text: this.getItemType ( item, item.type ).name } }>
                                                </Label>
                                            </div>
                                            {(item.inputs) ? (
                                                <div className='flex flex-ac flex-jsb w-100'>
                                                    {item.inputs.map((input, idx) => (
                                                        <Input
                                                            key={ index }
                                                            className={ 'child w-100 ' + input.class}
                                                            label={ "Item Description"}
                                                            type={ 'text' }
                                                            value={ input.value }
                                                            error={ !input.value }
                                                            success={ input.value != null }
                                                            onChange={ (e) => input.callback(e.target.value, index) }>
                                                        </Input>
                                                    ))}
                                                </div>
                                            ) : null}
                                            <div className='flex flex-jsb w-100'>
                                                <div className="child w-30 mr1 timeslotTypes">
                                                    <SelectDropdown
                                                        label="Type"
                                                        className="my0!"
                                                        selectText="Select an agenda item type"
                                                        default={ this.getItemType ( item, item.type ).name }
                                                        options={ item.types.map(( type, index ) => {
                                                            return { 
                                                                id : type.id,
                                                                name : type.name
                                                            }
                                                        } ) }
                                                        inputId={ 'basicSelectDropdown' }
                                                        callback={ (selected) => { item.typeUpdated(selected, index) } }>
                                                    </SelectDropdown>
                                                </div>
                                                { item.type === 5 ? 
                                                <div className='flex flex-jsb w-60'>
                                                    <SelectDropdown
                                                        className={ "w-100 " + ( item.moduleObject && item.moduleObject.file && item.moduleObject.file.file_size === "20" ? "module_error" : "" ) }
                                                        label={ "Course Modules" }
                                                        selectText="Select the course modules being used in this slot."
                                                        options={ this.props.modules.map(( course, index ) => {
                                                            return { 
                                                                id : course.id,
                                                                name : course.name
                                                            }
                                                        } ) }
                                                        default={ item.module }
                                                        search={ true }
                                                        success={ item.module }
                                                        inputId={ 'courseDropdown' }
                                                        callback={ (selected) => { item.moduleUpdated(selected, index) } }>
                                                    </SelectDropdown>
                                                </div>
                                                : 
                                                <div className='flex flex-jsb w-60 mt2'>
                                                    { this.getItemType ( item, item.type ).module != null ?
                                                        <p>( will automatically include '{ this.getItemType ( item, item.type ).module.name }' module. )</p>
                                                        : null 
                                                    }
                                                </div> }
                                                { item.type === 5 ? 
                                                <div className='flex flex-jsb w-10 px1 py2'
                                                    onClick={ () => { item.toggleRelated ( item, index ) } }>
                                                    <Icon
                                                        className={ 'primary' }
                                                        size={ '2x' }
                                                        type={ 'solid' }
                                                        icon={ item.related ? 'folder-open' : 'folder' }
                                                        
                                                        >
                                                    </Icon>
                                                </div>
                                                :  null }
                                            </div>
                                            { item.type === 5 ? 
                                                <div>
                                                    { item.moduleObject && this.getTags(item.moduleObject).length > 0 ?
                                                        <div className='flex flex-jsb w-100'>
                                                            <Tags
                                                                label={ "Also known as : " }
                                                                tags={ this.getTags(item.moduleObject) }
                                                                readonly={ true }
                                                                ></Tags>
                                                        </div>
                                                        : null }
                                                </div>
                                            : null }
                                            { item.type === 5 && item.related ? 
                                                <div>
                                                    <div className="flex w-100 mr0 mb1">
                                                        <div className="child w-10">
                                                            <div className="button primary action" 
                                                                style={ { padding: "0px" } }
                                                                onClick={ () => item.addRelatedModule( item, index ) }>
                                                                <Icon className="mx1" icon="file-plus" />
                                                            </div>
                                                        </div>
                                                        <div className="child w-80">
                                                            Use this button to add additional workbook elements to this agenda item.
                                                        </div>
                                                    </div>
                                                    <div className="flex w-100 mr0 mb1">
                                                        <div className="prioritised-list w-100">
                                                        { item.relatedModules.length > 0 ? 
                                                            <ReactDragList
                                                                dataSource={ item.relatedModules }
                                                                rowKey="key"
                                                                row={(module, rindex) => (
                                                                    <ContentCard key={ module.key } noPadding={ true } className="mb1 w-100">
                                                                        <div className="flex flex-ac flex-jsb px1">
                                                                            <div className="flex flex-ac w-20">
                                                                                Additional Module
                                                                            </div>
                                                                            <div className="flex flex-ac w-65 mt1">
                                                                                <SelectDropdown
                                                                                    className="w-100"
                                                                                    selectText="Select the course module being used in this slot."
                                                                                    options={ this.props.modules.map(( course, index ) => {
                                                                                        return { 
                                                                                            id : course.id,
                                                                                            name : course.name
                                                                                        }
                                                                                    } ) }
                                                                                    default={ module.name }
                                                                                    search={ true }
                                                                                    inputId={ 'courseDropdown' }
                                                                                    callback={ (selected) => { item.relatedModuleUpdated(selected, rindex, index) } }>
                                                                                </SelectDropdown>
                                                                            </div>
                                                                            <div className="flex flex-ac w-5">
                                                                                <button className={ "timeButton primary inverted" } onClick={ (e) => { this.deleteRelatedItem(rindex, index); } }>
                                                                                    <Icon icon="trash" type={ 'solid' } className="mx1"></Icon>
                                                                                </button>
                                                                            </div>
                                                                            <div className="flex flex-ac w-5">
                                                                                <Icon className="mx1" icon="grip-lines"></Icon>
                                                                            </div>
                                                                        </div>
                                                                    </ContentCard>
                                                                )}
                                                                handles={ false }
                                                                className="simple-drag"
                                                                rowClassName="simple-drag-row"
                                                                onUpdate={ (evt, updated) => { this.handleRelatedModuleUpdate( evt, updated, index ) } }>
                                                            </ReactDragList>
                                                        : null }
                                                        </div>
                                                    </div>
                                                </div>
                                            : null }
                                        </div>
                                    </div>
    							</div>
    							<Icon className="mx1" icon="grip-lines"></Icon>
    						</div>
    					</ContentCard>
    				)}
    				handles={ false }
    				className="simple-drag"
    				rowClassName="simple-drag-row"
    				onUpdate={ this.handleUpdate }
    			/>
                : 
                <ContentCard className="mb1">
                    Please add an agenda item using the button on the right.
                </ContentCard>
                }
    		</div>
    	);
    }
}

AgendaList.propTypes = {
    list: PropTypes.array.isRequired,
    modules : PropTypes.array.isRequired,
    changeCallback: PropTypes.func, 
    changeRelatedCallback: PropTypes.func, 
    dates: PropTypes.array
};
