import { Login, AcceptInvite, SendResetPassword, ResetPassword, Landing, Profile, Team, Clients, Client, Content, Course, Types} from './routes';

export default [{
  path: '/',
  exact: true,
  component: Login,
  private: false
}, {
  path: '/accept-invite/:id/:token',
  exact: true,
  component: AcceptInvite,
  private: false
}, {
  path: '/reset-password',
  exact: true,
  component: SendResetPassword,
  private: false
}, {
  path: '/reset-password/:id/:token',
  exact: true,
  component: ResetPassword,
  private: false
}, {
  path: '/profile',
  exact: true,
  component: Profile,
  private: true
}, {
  path: '/team',
  exact: true,
  component: Team,
  private: true
}, {
  path: '/landing',
  exact: true,
  component: Landing,
  private: true
}, {
  path: '/clients',
  exact: true,
  component: Clients,
  private: true
}, {
  path: '/types',
  exact: true,
  component: Types,
  private: true
}, {
  path: '/client/:id',
  exact: true,
  component: Client,
  private: true
}, {
  path: '/client/:id/course/:course_id',
  exact: true,
  component: Course,
  private: true
}, {
  path: '/content',
  exact: true,
  component: Content,
  private: true
}];
