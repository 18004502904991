import React, { Component } from 'react';
import { Redirect } from 'react-router';
import PageWrapper from '../components/PageWrapper';
import { Table, ToastNotification, Loader, ContentCard, Modal, Input, FileInput, AlertBanner, Textarea } from 'filament-ui';
import LoaderRing from '../images/loader.svg';
import axios from 'axios';
import moment from 'moment';
// import { WithContext as ReactTags } from 'react-tag-input';

import { API_ROOT } from '../apiConfig';

export default class Content extends Component {

  constructor(props) {
    super(props);

    this.toggleModal = this.toggleModal.bind(this);
    this.cancelModal = this.cancelModal.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.clickStep = this.clickStep.bind(this);
    this.fileUploadCallback = this.fileUploadCallback.bind(this);
    this.getModules = this.getModules.bind(this);
    this.deleteModule = this.deleteModule.bind(this);
    this.doDeleteModule = this.doDeleteModule.bind(this);
    this.editModule = this.editModule.bind(this);
    this.doEditModule = this.doEditModule.bind(this);
    this.downloadModule = this.downloadModule.bind(this);
    this.tableActionCallback = this.tableActionCallback.bind(this);

    this.handleDeletion = this.handleDeletion.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);

    this.clearToast = this.clearToast.bind(this);

    this.state = this.getInitialState();

    var _this = this;
    setTimeout ( function () { _this.getModules() }, 250 );
  }

  getInitialState() {
    const initialState = { 
      loading : true,
      showToast : false,

      showModal : false,
      steps: [{complete: false}, {complete: false}],
      createSteps: [{complete: false, heading: 'Element Information'}, {complete: false, heading: 'Element Content'}],
      currentStep: 0,

      modules: [],
      suggestions: [],

      module_name : "", 
      module_description: "", 
      module_file : {},

      tags: [],

      subHeaderRoutes: {
        action: {
          text: 'New Element',
          icon: 'plus',
          action: this.toggleModal
        },
        tabs: [

        ]
      }
    };

    return initialState;
  }

  getModules () { 
    var _this = this;
    return axios.get(`${API_ROOT}/db/modules/`, {
    }).then((resp) => {
      var rows = [];

      var modules = resp.data.documents;

      for ( var i = 0; i < modules.length; i++ ) {
        var module = modules[i];
        var row = [ ];
        row.push( { 
          id : 1,
          value : module.name
        } );
        // row.push( { 
        //   id : 2,
        //   value : module.description
        // } );

        row.push( { 
          id : 2,
          value : module.usage.length
        } );

        row.push( { 
          id : 3,
          value : ( module.file && module.file.file_size !== "TBD" ? this.humanFileSize(module.file.file_size) : "Error" )
        } );

        row.push( { 
          id : 4,
          value : ( module.usage.length === 0 ? "N/A" : moment(module.usage[module.usage.length - 1].course.start_date).format('MMM Do YYYY') )
        } );

        row.push( { 
          id : 5,
          value : moment(module.updated_at).format('MMM Do YYYY')
        } );

        row.push ( {
          id: 6,
          value: 'actions',
          actions: [{
            icon: 'edit',
            name: 'Edit',
            data : module,
            action: this.editModule
          }, {
            icon: 'download',
            name: 'Download',
            data : module,
            action: this.downloadModule
          }, {
            icon: 'trash',
            name: 'Delete',
            data : module,
            action: this.deleteModule
          }]
        } );

        // row.push ( {
        //   id: 5,
        //   data : module
        // } );

        rows.push ( row );
      }

      _this.setState( { modules: rows, loading : false } );
    });
  }

  downloadModule ( row, index, item ) { 
    axios.get(`${API_ROOT}/db/files/` + row.data.file_id, {
      responseType: 'blob'
    }).then((resp) => {
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', row.data.file.name);
      document.body.appendChild(link);
      link.click();
    });
  }


  deleteModule ( row, index, item ) { 
    this.setState ( { 
      moduleToDelete : row.data,
      showDeleteModal : true
    })
  }

  doDeleteModule () { 
    var thismodule = this.state.moduleToDelete;
    return axios.delete(`${API_ROOT}/db/modules/` + thismodule.id, {
    }).then((resp) => {
      this.setState ( { 
        moduleToDelete : null,
        showDeleteModal : false
      }, () => { 
        this.getModules();
      });
    });
  }

  cancelDeleteModal () { 
    this.setState ( { 
      moduleToDelete : null,
      showDeleteModal : false
    });
  }

  doEditModule ( props ) { 
    var _this = this;

    let formData = new FormData();
    if ( props.module_file.name ) { 
      formData.append('file', props.module_file );
    }
    formData.append('name', props.module_name );
    formData.append('description', props.module_description );
    formData.append('keyword_csv', JSON.stringify(props.tags));

    return axios.put(`${API_ROOT}/db/modules/` + props.module_id, formData, {

    }).then((resp) => {
      _this.getModules();
    });
  }

  editModule ( row, index, item ) { 
    var thisModule = row.data;
    var tags = [];
    if ( thisModule.keyword_csv != null ) { 
      try { 
        tags = JSON.parse(thisModule.keyword_csv)
      } catch ( e ) {
        console.log("error ! ", e);
      }
    }

    this.setState ( { 
      steps: [{complete: false}, {complete: false}],
      createSteps: [{complete: false, heading: 'Module Information'}, {complete: false, heading: 'Module Content'}],
      currentStep: 0,
      module_id : thisModule.id,
      module_name : thisModule.name,
      module_description: thisModule.description,
      tags : tags
    }, () => { 
      this.toggleModal();
    } );
  }

  createModule ( props ) { 
    var _this = this;
    if ( props != null ) { 
      let formData = new FormData();
      formData.append('file', props.module_file );
      formData.append('name', props.module_name );
      formData.append('description', props.module_description );
      formData.append('keyword_csv', JSON.stringify(props.tags));

      return axios.post(`${API_ROOT}/db/modules`, formData, {

      }).then((resp) => {
        _this.getModules();
      });
    } else { 
      _this.getModules();
    }
  }

  viewSection(redirect) {
    this.setState({ redirect });
  }

  fileUploadCallback(file) {
    if ( file.size > 0 && file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ) { 
      this.setState({module_file: file});
    } else { 
      // zero byte file size... 
      this.setState ( { 
        showToast : true,
        toastMessage : { 
          "type" : "error",
          "message" : "Unable to upload file. Please check file type and size."
        }
      })
    }
  }

  cancelModal () { 
    this.setState({
      showModal: false, 
      module_id : null,
      module_name : "", 
      module_description: "", 
      module_file : {},
      tags : [],
      steps: [{complete: false}, {complete: false}],
      currentStep: 0
    });

    var _this = this;
    setTimeout ( function () { _this.getModules() }, 250 );
  }


  toggleModal(modalOutput) {
    if (this.state.showModal) {
      this.setState(this.getInitialState());
      if ( modalOutput && modalOutput.module_id != null ) { 
        this.doEditModule( modalOutput );
      } else { 
        this.createModule( modalOutput );
      }
    } else {
      this.setState({showModal: true});
    }
  }

  nextStep() {
    let { steps, currentStep, createSteps } = this.state;
    steps[0].complete = true;
    createSteps[0].complete = true;
    currentStep = 1;

    this.setState({steps, createSteps, currentStep});
  }

  clickStep(step, index) {
    let { steps, createSteps, currentStep } = this.state;

    if (step.complete) {
      currentStep = index;

      steps.map((st, ind) => st.complete = (ind >= index) ? false : true);
      createSteps.map((st, ind) => st.complete = (ind >= index) ? false : true);

      this.setState({
        steps,
        createSteps,
        currentStep
      });
    }
  }

  handleDeletion(i) {
    const { tags, suggestions } = this.state;

    if (tags.length) {
      let newSuggestions = suggestions;
      newSuggestions.push({id: tags[i].id, text: tags[i].text});

      this.setState(state => ({
        tags: tags.filter((tag, index) => index !== i)
      }));
    }
  }

  handleAddition(tag) {
    const { tags, suggestions } = this.state;

    let newSuggestions = suggestions.filter((sug) => {
      return tag.text.toLowerCase() !== sug.text.toLowerCase();
    });

    this.setState(state => ({
      tags: [...tags, tag],
      suggestions: newSuggestions
    }));
  }

  handleDrag(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    this.setState({ tags: newTags });
  }

  tableActionCallback(row, item) {
    this.viewSection("/client/" + row[0].id);
  }

  clearToast () { 
    this.setState ( { showToast : false } );
  }

  humanFileSize(bytes, si=false, dp=1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si 
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
  }
  
  render() {
    let { subHeaderRoutes, redirect, showModal } = this.state;

    const sortOptionsSelect = {
      col: 0, 
      sort: 'desc'
    };

    const tableHeaders = [ "Name", "Times Used", "Size", "Last Used", "Updated", "" ];

    if (redirect) {
      return <Redirect push to={redirect} />;
    }

    return (
      <PageWrapper headerBar={ true } search={ true } sideMenu={ true } subHeader={ subHeaderRoutes }>
        <h2 className="mt0">Workshop Content</h2>
        <p className="t-mt1">The table below lists all of the elements available to be used in courses for a client.</p>

        {(this.state.loading) ? <Loader type={ LoaderRing } size="small"></Loader> : null}

        {(!this.state.loading) ? (
          <div>
          {this.state.modules.length > 0 ? (
            <Table
              headers={ tableHeaders }
              widths={ [ 35,15,15,15,15,5 ] }
              data={ this.state.modules }
              sortOptions={ sortOptionsSelect }
              type="table">
            </Table>
          ) : (
            <ContentCard centred={ true }>
              <div className="flex flex-ac flex-col">
                <h5>No Modules</h5>
                <p>There are no modules created.</p>
              </div>
            </ContentCard>
          )}
          </div>
        ): null }

        {(this.state.showToast) ? (
          <ToastNotification 
            type={ this.state.toastMessage.type } 
            message={ this.state.toastMessage.message } 
            onClick={ this.clearToast }></ToastNotification>
        ) : null}

      
        {showModal ? (
          <Modal
            toggleModal={ () => { this.cancelModal() } }
            header="Create or Edit a Workbook Element"
            subheader="Follow these simple steps to create an element that can then be included in a workshop that is created for a client."
            steps={ this.state.createSteps }
            currentStep={ this.state.currentStep }
            clickSteps={ this.clickStep }>

            {((!this.state.createSteps[0].complete) ? (
              <form className="w-100">
                <Input label="Element Name"
                  inputId="name"
                  type="text"
                  placeholder=""
                  value={ this.state.module_name }
                  onChange={ (e) => this.setState({ module_name : e.target.value }) }>
                </Input>

                <Textarea label="Description"
                  placeholder="Any descriptive text about the module."
                  rows={ 5 }
                  value={ this.state.module_description }
                  onChange={ (e) => this.setState({ module_description : e.target.value }) }>
                </Textarea>
              </form>
            ) : (
              <form className="w-100">
                <AlertBanner message="Please make sure that the file uploaded is a DOCX file and is formatted correctly." type="warning"></AlertBanner>

                <FileInput label="Element Content"
                  inputId="filePicker1"
                  callback={ this.fileUploadCallback }>
                </FileInput>


                {/* <ReactTags
                  tags={tags}
                  handleDelete={this.handleDeletion}
                  handleAddition={this.handleAddition}
                  inputFieldPosition="bottom"
                /> */}

                {/* <Tags
                  label={ "Wireframe Phrases" }
                  placeholder={ "Enter example text here." }
                  tags={ tags }
                  allowDragDrop={ false }
                  autofocus={ false }
                  handleAddition={ this.handleAddition }
                  handleDeletion={ this.handleDeletion }>
                </Tags> */}

              </form>
            ))}

            <div className="w-100 flex flex-afe flex-jfe mt3">
              <button onClick={ () => this.cancelModal() } className="button primary inverted mr1">Cancel</button>
              {((!this.state.createSteps[this.state.steps.length - 2].complete) ? (
                <button className="button primary" disabled={ this.state.module_name.length === 0 } onClick={ () => this.nextStep() }>Next</button>
              ) : (
                <button className="button primary" disabled={ ( !this.state.module_file.name || this.state.module_file.name.length === 0 ) && this.state.module_id == null } onClick={ () => this.toggleModal(this.state) }>Save</button>
              ))}
            </div>
          </Modal>
        ) : null}

        { this.state.showDeleteModal ?
          <Modal
            toggleModal={ this.cancelDeleteModal }
            header="Delete Element"
            headerIcon={ 'trash' }
            subheader="Are you sure?"
            actions={ [
              {name: 'Confirm', class: 'primary', action: () => this.doDeleteModule()},
              {name: 'Cancel', class: 'primary inverted', action: () => this.cancelDeleteModal()}
            ] }
            fitModal={ true }>
              <p>Deleting this element will remove the template and you will no longer be able to include this module in any courses. There is no way to undo this. Are you sure you want to do that?</p>
          </Modal>
          : null }

      </PageWrapper>
    )
  }
}