import React, { Component } from 'react';
import { Redirect } from 'react-router';
import PageWrapper from '../components/PageWrapper';
import { Table, Loader, ContentCard, Modal, Input, FileInput, AlertBanner, Textarea, ToastNotification } from 'filament-ui';
import LoaderRing from '../images/loader.svg';
import axios from 'axios';
import moment from 'moment';

import { API_ROOT } from '../apiConfig';

export default class Clients extends Component {

  constructor(props) {
    super(props);

    this.toggleModal = this.toggleModal.bind(this);
    this.cancelModal = this.cancelModal.bind(this);
    this.deleteClient = this.deleteClient.bind(this);
    this.doDeleteClient = this.doDeleteClient.bind(this);
    this.cancelDeleteModal = this.cancelDeleteModal.bind(this);
    this.editClient = this.editClient.bind(this);
    this.doEditClient = this.doEditClient.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.clickStep = this.clickStep.bind(this);
    this.fileUploadCallback = this.fileUploadCallback.bind(this);
    this.getAllClients = this.getAllClients.bind(this);
    this.viewClient = this.viewClient.bind(this);
    this.downloadTemplate = this.downloadTemplate.bind(this);

    this.state = this.getInitialState();

    var _this = this;
    setTimeout ( function () { _this.getAllClients() }, 250 );
  }

  getInitialState() {
    const initialState = { 
      loading : true,
      showToast : false,

      showModal : false,
      steps: [{complete: false}, {complete: false}],
      createSteps: [{complete: false, heading: 'Baisc Information'}, {complete: false, heading: 'Templates'}],
      currentStep: 0,

      clientData : [], 
      client_name : "",
      client_description : "",
      client_website: "",
      client_file : {},

      subHeaderRoutes: {
        action: {
          text: 'New Client',
          icon: 'plus',
          action: this.toggleModal
        },
        tabs: [

        ]
      }
    };

    return initialState;
  }

  humanFileSize(bytes, si=false, dp=1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si 
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
  }

  downloadTemplate ( row, index, item ) { 
    console.log(`download = ${API_ROOT}/db/files/${row.data.template.id}`)
    axios.get(`${API_ROOT}/db/files/` + row.data.template.id, {
      responseType: 'blob'
    }).then((resp) => {
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', row.data.template.name);
      document.body.appendChild(link);
      link.click();
    });
  }

  getAllClients () { 
    var _this = this;
    return axios.get(`${API_ROOT}/db/clients`, {
      }
    ).then((resp) => {
      var rows = [];
      var clients = resp.data.documents;
      for ( var i = 0; i < clients.length; i++ ) {
        var client = clients[i];

        var courseDate = "N/A";
        if ( client.courses.length > 0 ) { 
          courseDate = client.courses[client.courses.length - 1].start_date;
        }

        var row = [ ];
        row.push( { 
          id : 1,
          value : '<a href="' + client.website + '">' + client.name + '</a>'
        } );
        row.push( { 
          id : 2,
          value : this.humanFileSize(client.template.file_size)
        } );
        row.push( { 
          id : 3,
          value : client.courses.length
        } );
        row.push( { 
          id : 4,
          value : client.courses.length > 0 ? moment(courseDate).format('MMMM Do YYYY') : "N/A"
        } );

        row.push ( {
          id: 5,
          value: 'actions',
          actions: [ {
            icon: 'trash',
            name: "View",
            data: client,
            action: _this.viewClient
          }, {
            icon: 'edit',
            name : "Edit",
            data: client,
            action: _this.editClient
          }, {
            icon: 'download',
            name : "Download",
            data: client,
            action: _this.downloadTemplate
          }, {
            icon: 'trash',
            name: "Delete",
            data: client,
            action: _this.deleteClient
          } ] 
        } );

        rows.push ( row );
      }
      _this.setState( { clientData : rows, loading: false } );
    });
  }

  createClient ( props ) { 
    var _this = this;

    if ( props != null ) { 

      let formData = new FormData();
      formData.append('file', props.client_file );
      formData.append('name', props.client_name );
      formData.append('description', props.client_description );
      formData.append('website', props.client_website );

      return axios.post(`${API_ROOT}/db/clients`, formData, {

      }).then((resp) => {
        _this.getAllClients();
      });
    } else { 
      _this.getAllClients();
    }
  }

  doEditClient ( props ) { 
    var _this = this;

    let formData = new FormData();
    if ( props.client_file.name != null ) { 
      formData.append('file', props.client_file );
    }

    formData.append('name', props.client_name );
    formData.append('description', props.client_description );
    formData.append('website', props.client_website );

    return axios.put(`${API_ROOT}/db/clients/` + props.client_id, formData, {

    }).then((resp) => {
      _this.getAllClients();
    });
  }

  viewSection(redirect) {
    this.setState({ redirect });
  }

  fileUploadCallback(file) {
    if ( file.name.indexOf(".docx") > 0 ) { 
      this.setState({client_file: file});
    } else { 
      this.setState ( { 
        toastMessage: {
          type: 'warning', 
          message: 'Only .DOCX file formats are allowed.'
        }
      })
    }
  }

  editClient ( row, index, item ) { 
    var thisClient = row.data;
    this.setState ( { 
      client_id : thisClient.id,
      client_name : thisClient.name,
      client_website : thisClient.website,
      client_description: thisClient.description
    } )
    this.toggleModal();
  }



  deleteClient ( row, index, item ) { 
    this.setState ( { 
      showDeleteModal : true,
      clientToDelete : row.data
    })
  }

  doDeleteClient () {
    var client = this.state.clientToDelete; 

    return axios.delete(`${API_ROOT}/db/clients/` + client.id, {

    }).then((resp) => {
      this.setState ( { 
        showDeleteModal : false,
        clientToDelete : null
      }, () => { 
        this.getAllClients();
      } );
    });
  }

  cancelDeleteModal () { 
    this.setState ( { 
      showDeleteModal : false,
      clientToDelete : null
    });
  }

  toggleModal(modalOutput) {
    if (this.state.showModal) {
      this.setState(this.getInitialState());
      if ( modalOutput && modalOutput.client_id != null ) { 
        this.doEditClient ( modalOutput );
      } else { 
        this.createClient ( modalOutput );
      }
    } else {
      this.setState({showModal: true});
    }
  }

  cancelModal () { 
    this.setState({
      showModal: false,
      client_id : null, // make sure data is cleared... 
      client_name : "",
      client_description : "",
      client_website: "",
      client_file : {}
    });

    var _this = this;
    setTimeout ( function () { _this.getAllClients() }, 250 );
  }

  nextStep() {
    let { steps, currentStep, createSteps } = this.state;
    steps[0].complete = true;
    createSteps[0].complete = true;
    currentStep = 1;

    this.setState({steps, createSteps, currentStep});
  }

  clickStep(step, index) {
    let { steps, createSteps, currentStep } = this.state;

    if (step.complete) {
      currentStep = index;

      steps.map((st, ind) => st.complete = (ind >= index) ? false : true);
      createSteps.map((st, ind) => st.complete = (ind >= index) ? false : true);

      this.setState({
        steps,
        createSteps,
        currentStep
      });
    }
  }

  viewClient( row, index, item ) {
    var thisClient = row.data;
    this.viewSection("/client/" + thisClient.id);
  }

  render() {
    let { subHeaderRoutes, redirect, showModal } = this.state;

    const sortOptionsSelect = {
      col: 0,
      sort: 'desc'
    };

    const tableHeaders = [ "Name", "Template", "Courses", "Last Workshop", ""];

    if (redirect) {
      return <Redirect push to={redirect} />;
    }

    return (
      <PageWrapper headerBar={ true } search={ true } sideMenu={ true } subHeader={ subHeaderRoutes }>
        <h2 className="mt0">Clients</h2>
        <p className="t-mt1">The following table lists all clients currently in the system. Click on a client to see more information about that client, or to create a workbook for them.</p>

        {(this.state.loading) ? <Loader type={ LoaderRing } size="small"></Loader> : null}

        {(!this.state.loading) ? (
          <div>
          {this.state.clientData.length > 0 ? (
            <Table
              headers={ tableHeaders }
              widths={ [ 55,10,10,20,5 ] }
              data={ this.state.clientData }
              sortOptions={ sortOptionsSelect }
              clickable={ true }
              type="table">
            </Table>
          ) : (
            <ContentCard centred={ true }>
              <div className="flex flex-ac flex-col">
                <h5>No Clients</h5>
                <p>There are no clients created in the database at the moment..</p>
              </div>
            </ContentCard>
          )}
          </div>
        ): null }

        {showModal ? (
          <Modal
            toggleModal={ this.toggleModal }
            header="Create a new Client"
            subheader="Follow these simple steps to create a client, which can then be associated with courses and other information."
            steps={ this.state.createSteps }
            currentStep={ this.state.currentStep }
            clickSteps={ this.clickStep }>
            {((!this.state.createSteps[0].complete) ? (
              <form className="w-100">
                <Input label="Client Name"
                  inputId="name"
                  type="text"
                  placeholder="Business Name"
                  autocomplete="off"
                  value={ this.state.client_name }
                  onChange={ (e) => this.setState({ client_name : e.target.value }) }>
                </Input>

                <Input label="Client Website"
                  inputId="name"
                  type="text"
                  placeholder="https://..."
                  autocomplete="off"
                  value={ this.state.client_website }
                  onChange={ (e) => this.setState({ client_website : e.target.value }) }>
                </Input>

                <Textarea label="Description"
                  placeholder="Any descriptive text about the client."
                  rows={ 5 }
                  value={ this.state.client_description }
                  onChange={ (e) => this.setState({ client_description : e.target.value }) }>
                </Textarea>
              </form>
            ) : (
              <form className="w-100">
                <AlertBanner message="Please make sure that the file uploaded is a DOCX file and is formatted correctly." type="warning"></AlertBanner>

                <FileInput label="Default document template"
                  inputId="filePicker1"
                  callback={ this.fileUploadCallback }>
                </FileInput>
              </form>
            ))}

            <div className="w-100 flex flex-afe flex-jfe mt3">
              <button onClick={ () => this.cancelModal() } className="button primary inverted mr1">Cancel</button>
              {((!this.state.createSteps[this.state.steps.length - 2].complete) ? (
                <button className="button primary" disabled={ this.state.client_name.length === 0 || this.state.client_description.length === 0 } onClick={ () => this.nextStep() }>Next</button>
              ) : (
                <button className="button primary" disabled={ (!this.state.client_file.name || this.state.client_file.name.length === 0) && this.state.client_id == null } onClick={ () => this.toggleModal(this.state) }>Save</button>
              ))}
            </div>
          </Modal>
        ) : null}

        { (this.state.toastMessage) && (
            <ToastNotification
                type={ this.state.toastMessage.type }
                message={ this.state.toastMessage.message }
                onClick={ () => this.setState({toastMessage: null}) }
            />
        ) }

        { this.state.showDeleteModal ?
          <Modal
            toggleModal={ this.cancelDeleteModal }
            header="Delete Client"
            headerIcon={ 'trash' }
            subheader="Are you sure?"
            actions={ [
              {name: 'Confirm', class: 'primary', action: () => this.doDeleteClient()},
              {name: 'Cancel', class: 'primary inverted', action: () => this.cancelDeleteModal()}
            ] }
            fitModal={ true }>
              <p>Deleting this client will remove all of the information associated with this client including the modules, wireframes and generated files. There is no way to undo this. Are you sure you want to do that?</p>
          </Modal>
          : null }


      </PageWrapper>
    )
  }
}
