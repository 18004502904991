import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../actions/auth';
import Logo from '../images/Carter_Corson_Master_Logo-CentralStack.jpg';
import CCLogo from '../images/masthead-background.jpg';
import { Input } from 'filament-ui';

class Login extends Component {

  constructor(props) {
    super(props);

    this.submitLoginForm = this.submitLoginForm.bind(this);

    this.state = {
      showSuccess: false,
      showError: false,
      message: '',
      email: '',
      password: ''
    };
  }

  componentDidUpdate(prevProps) {
    const { isLoginSuccess, loginError } = this.props;

    if (prevProps.isLoginSuccess !== isLoginSuccess && isLoginSuccess) {
      this.props.history.push('/clients');
    }

    if (prevProps.loginError !== loginError && loginError) {
      this.setState({showError: true, message: 'Your login credentials are incorrect. Please try again.'});
    }
  }


  // componentDidMount() {
	// 	const { isLoginSuccess } = this.props;

	// 	if (isLoginSuccess) {
	// 		this.props.history.push('/landing');
	// 	}
	// }

	// componentDidUpdate(prevProps) {
	// 	const { isLoginSuccess, loginError } = this.props;

	// 	if (isLoginSuccess !== prevProps.isLoginSuccess || loginError !== prevProps.loginError) {
	// 		if (isLoginSuccess) {
	// 			this.props.history.push('/landing');
	// 		} else if (loginError) {
	// 			this.setState({
	// 				showError: true,
	// 				message: 'Your login credentials are incorrect. Please try again.'
	// 			});
	// 		}
	// 	}
	// }

  submitLoginForm(e) {
    e.preventDefault();

    const { email, password } = this.state;
    const { login } = this.props;

    const validEmail = this.validateEmail(email);

		if (validEmail) {
			login(email, password);
		} else {
			this.setState({
				showError: true,
				message: 'Please enter a valid email address.'
			});
		}
	}

	validateEmail(email) {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		return re.test(String(email).toLowerCase());
  }

  render() {
    let { email, password, showError, message } = this.state;

    return (
      <div className="flex flex-ac flex-jsb">
        <div className="page-height bg-white flex-centred w-50">
          <header className="w-65 w-85-tab">
            <Link to="/"><img src={ Logo } alt="App Logo" className="w-95 mb1" /></Link>
            <h4>Login</h4>
            <p className="accent-dark small">
              Enter your email and password to login to the platform.
            </p>

            <form className="my2">
              <Input type="email"
                placeholder="Enter your email address..."
                inputId="emailAddress"
                label="Email"
                error={ showError }
                errorMessage={ message }
                value={ email }
                onChange={ (e) => this.setState({email: e.target.value, showError: false}) }>
              </Input>

              <Input type="password"
                placeholder="Enter your password..."
                inputId="password"
                label="Password"
                error={ showError }
                value={ password }
                onChange={ (e) => this.setState({password: e.target.value, showError: false}) }>
              </Input>

              <div className="flex flex-afs flex-jfs">
                <p className="small accent-dark mt0 t-mr1">Forgotten your password?</p>
                <Link className="small" to="/reset-password">Reset it.</Link>
              </div>

              <button className="primary w-100 mt2"
                onClick={ (e) => this.submitLoginForm(e) }
                disabled={ email.length === 0 || password.length === 0 || showError }>
                  Login
              </button>

              {/* <div className="flex flex-ac flex-jc mt1">
                  <p className="small accent-dark mt0 t-mr1">Don't yet have an account?</p>
                  <Link className="small" to="/create-subscription">Create Account.</Link>
              </div> */}
            </form>

          </header>
        </div>

        <div className="w-50 page-height full-page-image"
          style={{backgroundImage: "url(" + CCLogo + ")"}}>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isLoginPending: state.login.isLoginPending,
    isLoginSuccess: state.login.isLoginSuccess,
    loginError: state.login.loginError,
    authToken: state.login.authToken
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password) => dispatch(login(email, password))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
