import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { login } from '../actions/auth';
import { INVITE_USER } from '../apiConfig';
import Logo from '../images/filament-logo-dark.svg';
import { Input, ToastNotification } from 'filament-ui';

class AcceptInvite extends Component {

  constructor(props) {
    super(props);

    this.state = {
      id: '',
      token: '',
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      passwordConfirm: '',
      toastMessage: null
    };

    this.displayToastMessage = this.displayToastMessage.bind(this);
  }

  componentWillMount() {
    this.setState({
      id: this.props.match.params.id,
      token: this.props.match.params.token
    });

    const endpoint = `${INVITE_USER}/` + this.props.match.params.id + '/' + this.props.match.params.token;

    axios.get(endpoint).then((resp) => {
      this.setState({
        first_name: resp.data.first_name,
        last_name: resp.data.last_name,
        email: resp.data.email
      });
    }).catch((err) => {
      this.displayToastMessage({type: 'error', message: err.message});
    });
  }

  displayToastMessage(message) {
    this.setState({toastMessage: message});
  }

  registerAccount(event) {
    event.preventDefault();

    const payload = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      password: this.state.password
    };

    const endpoint = `${INVITE_USER}/` + this.props.match.params.id + '/' + this.props.match.params.token;

    axios.post(endpoint, payload).then((resp) => {
      if (resp.status === 200) {
        this.props.login(payload.email, payload.password);

        this.timeoutHandle = setTimeout(() => {
          if (this.props.isLoginSuccess) {
            this.props.history.push('/clients');
          } else if (this.props.loginError) {
            this.props.history.push('/');
          }
        }, 1000);
      }
    }).catch((err) => {
      this.displayToastMessage({type: 'error', message: err.message});
    });
  }

  render() {
    return (
      <div className="flex flex-ac flex-jsb">
        <div className="page-height bg-white flex-centred w-100">
          <div className="w-55 w-85-tab">
            <img src={ Logo } alt="App Logo" className="w-35 mb5" />
            <h4>Hey, { this.state.first_name + ' ' + this.state.last_name}</h4>
            <p className="accent-dark small">
              You have been invited to the join the team. Please choose a password to register your account with the email address <strong>{ this.state.email }</strong>.
            </p>

            <form className="my2">
              <Input type="password"
                placeholder="Enter your password..."
                inputId="password"
                label="Password"
                error={ this.state.password.length > 0 && this.state.password.length < 8 }
                errorMessage="Please make sure your password is at least 8 characters."
                value={ this.state.password }
                onChange={ (e) => this.setState({password: e.target.value}) }>
              </Input>

              <Input type="password"
                placeholder="Confirm your password..."
                inputId="passwordConfirm"
                label="Confirm Password"
                error={ this.state.password.length > 0 && this.state.passwordConfirm.length > 0 && this.state.passwordConfirm !== this.state.password }
                errorMessage="Please make sure both password fields match."
                value={ this.state.passwordConfirm }
                onChange={ (e) => this.setState({passwordConfirm: e.target.value}) }>
              </Input>

              <button className="primary w-100 t-mt2"
                disabled={ this.state.password.length === 0 || this.state.password.length < 8 || this.state.passwordConfirm.length === 0 || this.state.password !== this.state.passwordConfirm }
                onClick={ (e) => this.registerAccount(e) }>
                Register Account
              </button>
            </form>
          </div>
        </div>

        {(this.state.toastMessage) ? (
          <ToastNotification type={ this.state.toastMessage.type } message={ this.state.toastMessage.message } onClick={ () => this.displayToastMessage(null) }></ToastNotification>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isLoginPending: state.login.isLoginPending,
    isLoginSuccess: state.login.isLoginSuccess,
    loginError: state.login.loginError
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password) => dispatch(login(email, password))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AcceptInvite);
