import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import PageWrapper from '../components/PageWrapper';
import { USER, INVITE_USER } from '../apiConfig';
import LoaderRing from '../images/loader.svg';
import { Table, Modal, Input, OptionCard, ToastNotification, ContentCard, Loader } from 'filament-ui';
import { hasPermission } from '../actions/auth';

class Team extends Component {

  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.toggleDeleteUserModal = this.toggleDeleteUserModal.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.inviteUser = this.inviteUser.bind(this);
    this.setActiveTab = this.setActiveTab.bind(this);
    this.displayToastMessage = this.displayToastMessage.bind(this);
  }

  getInitialState() {
    const initialState = {
      users: [],
      usersTable: [],
      invitedUsersTable: [],
      selectedUser: null,
      selectedUserObj: null,
      loader: true,
      showModal: false,
      showConfirmModal: false,
      role_id: 2,
      inviteeIsAdmin: false,
      activeTab: 'Active Users',
      firstName: '',
      lastName: '',
      email: '',
      toastMessage: null
    };

    return initialState;
  }

  selectUserRole(value) {
    this.setState({role_id: value});
  }

  componentWillMount() {
    axios.get(USER, {
      count: 100
    }).then((resp) => {
      if (resp.data.documents && resp.data.count > 0) {
        this.setState({users: resp.data.documents.sort((a, b) => (a.is_admin === b.is_admin) ? 0 : a.is_admin ? -1 : 1 )});
        this.setupUsersTable();
      }
    }).catch((err) => {
      this.displayToastMessage({type: 'error', message: err});
    });
  }

  setActiveTab(tab) {
    this.setState({activeTab: tab.name});
  }

  displayToastMessage(message) {
    this.setState({toastMessage: message});
  }

  setupUsersTable() {

    let usersTable = [], invitedUsersTable = [];
    const currentUserIsAdmin = hasPermission(this.props.currentUser, 'can-manage-users');

    this.state.users.forEach((user) => {
      const isAdmin = hasPermission(user, 'can-manage-users');
      if (user.is_active) {
        usersTable.push([{
          id: 1,
          userId: user.id,
          value: user.first_name.charAt(0).toUpperCase() + user.first_name.substr(1) + ' ' + user.last_name.charAt(0).toUpperCase() + user.last_name.substr(1)
        }, {
          id: 2,
          value: (isAdmin) ? 'Administrator' : 'Standard User',
          status: (isAdmin) ? 'success' : 'primary'
        }, {
          id: 3,
          value: user.email
        }, {
          id: 4,
          value: (!isAdmin && currentUserIsAdmin) ? 'action' : null,
          action: (!isAdmin && currentUserIsAdmin) ? ({
            icon: 'minus-circle',
            action: this.deleteUser
          }) : null
        }]);
      } else if (user.email) {
        invitedUsersTable.push([{
          id: 1,
          userId: user.id,
          value: user.first_name.charAt(0).toUpperCase() + user.first_name.substr(1) + ' ' + user.last_name.charAt(0).toUpperCase() + user.last_name.substr(1)
        }, {
          id: 2,
          value: 'Inactive',
          status: 'error'
        }, {
          id: 3,
          value: (isAdmin) ? 'Administrator' : 'Standard User',
          status: (isAdmin) ? 'success' : 'primary'
        }, {
          id: 4,
          value: user.email
        }, {
          id: 5,
          value: (!isAdmin && currentUserIsAdmin) ? 'action' : null,
          action: (!isAdmin && currentUserIsAdmin) ? ({
            icon: 'minus-circle',
            action: this.deleteUser
          }) : null
        }]);
      }
    });

    this.setState({
      usersTable: usersTable,
      invitedUsersTable: invitedUsersTable,
      loader: false
    });
  }

  deleteUser(user) {
    const userId = user[0].userId;
    const selectedUserObj = this.state.users.find((user) => {
      return user.id === userId;
    });

    this.setState({selectedUser: userId, selectedUserObj: selectedUserObj});

    this.toggleDeleteUserModal();
  }

  toggleDeleteUserModal(modalOutput) {
    if (!this.state.showConfirmModal) {
      document.body.classList.add('modal-open');
      this.setState({showConfirmModal: true});
    } else {
      document.body.classList.remove('modal-open');
      this.setState({showConfirmModal: false});

      if (modalOutput) {
        this.setState({loader: true});

        const endpoint = `${USER}/` + this.state.selectedUser;

        axios.delete(endpoint).then((resp) => {
          if (!resp.error) {
            const userItem = this.state.users.findIndex((user) => {
              return user.id === this.state.selectedUser;
            });

            let newUsers = this.state.users;
            newUsers.splice(userItem, 1);

            this.displayToastMessage({type: 'success', message: 'You have successfully removed this user from your account.'});
            this.setState({users: newUsers, selectedUser: null, selectedUserObj: null});
            this.setupUsersTable();
          }
        }).catch((err) => {
          this.displayToastMessage({type: 'error', message: err.message});
        });
      }
    }
  }

  inviteUser(modalOutput) {
    if (!this.state.showModal) {
      document.body.classList.add('modal-open');
      this.setState({showModal: true});
    } else {
      document.body.classList.remove('modal-open');
      this.setState({showModal: false});

      if (modalOutput) {
        this.setState({
          firstName: '',
          lastName: '',
          email: '',
          role_id: 2
        });

        axios.post(INVITE_USER, modalOutput).then((resp) => {
          this.displayToastMessage({type: 'success', message: 'You have successfully invited this user to your account.'});
        }).catch((err) => {
          this.displayToastMessage({type: 'error', message: err.message});
        });
      }
    }
  }

  render() {
    let { currentUser } = this.props;

    const headers = ['Name', 'Type', 'Email', ''], invitedHeaders = ['Name', 'Status', 'Type', 'Email', ''];
    const widths = [40, 27, 27, 6], invitedWidths = [34, 20, 20, 20, 6];

    const sortOptions = {
      col: 1,
      sort: 'asc'
    };

    let subHeaderRoutes = {
      tabs: [ {name: 'Active Users', callback: this.setActiveTab} ]
    };

    if (hasPermission(currentUser, "can-manage-users")) {
      subHeaderRoutes.tabs.push({name: 'Invited Users', callback: this.setActiveTab});
    }

    return (
      <PageWrapper headerBar={ true } search={ true } sideMenu={ true } subHeader={ subHeaderRoutes }>
        <div className="mb2 flex flex-ac flex-jsb">
          <div>
            <h2 className="mt0">Team Members</h2>
            <p>View and manage all current users, or invite new people to the platform.</p>
          </div>
          {currentUser && hasPermission(currentUser, "can-manage-users") ? (
            <button className="primary"
              onClick={ () => this.inviteUser() }>
              Invite New User
            </button>
          ) : null}
        </div>

        {(this.state.loader) ? <Loader type={ LoaderRing } size="small"></Loader> : null}

        {(!this.state.loader) ? (
          <div>
            {this.state.users.length > 0 && this.state.activeTab === 'Active Users' ? (
              <Table
                headers={ headers }
                widths={ widths }
                data={ this.state.usersTable }
                sortOptions={ sortOptions }
                type="table"
                title="Users Table">
              </Table>
            ) : (this.state.users.length > 0 && this.state.invitedUsersTable.length > 0 && this.state.activeTab === 'Invited Users') ? (
              <Table
                headers={ invitedHeaders }
                widths={ invitedWidths }
                data={ this.state.invitedUsersTable }
                sortOptions={ sortOptions }
                type="table"
                title="Invited Users Table">
              </Table>
            ) : (
              <ContentCard centred={ true }>
                <div className="flex flex-ac flex-col">
                  <h3>No Inactive Users</h3>
                  <p>There are no inactive users on this account.</p>
                </div>
              </ContentCard>
            )}
          </div>
        ) : null}

        {(this.state.showModal) ? (
          <Modal toggleModal={ this.inviteUser }
            header="Invite New User"
            subheader="As an administrator of this account, you are able to add new users."
            steps={ this.state.steps }>
            <form className="w-100">
              <div className="my1 flex flex-ac flex-jsb">
                <OptionCard className="mr1"
                  onClick={ () => this.selectUserRole(1) }
                  icon="user-plus"
                  header="Administrator"
                  subheader="Full access to all features."
                  selected={ this.state.role_id === 1}
                  fill={ true }>
                </OptionCard>
                <OptionCard onClick={ () => this.selectUserRole(2) }
                  icon="user-circle"
                  header="Standard User"
                  subheader="Access to features, except account-level."
                  selected={ this.state.role_id !== 1}
                  fill={ true }>
                </OptionCard>
              </div>

              <div className="flex flex-ac flex-jsb">
                <Input label="First Name"
                  className="w-49 mb0"
                  inputId="firstName"
                  type="text"
                  placeholder="Invitee's first name..."
                  value={ this.state.firstName }
                  onChange={ (e) => this.setState({firstName: e.target.value}) }>
                </Input>

                <Input label="Last Name"
                  className="w-49 mb0"
                  inputId="lastName"
                  type="text"
                  placeholder="Invitee's last name..."
                  value={ this.state.lastName }
                  onChange={ (e) => this.setState({lastName: e.target.value}) }>
                </Input>
              </div>

              <Input label="Email"
                inputId="email"
                type="text"
                placeholder="Enter the user's email address..."
                value={ this.state.email }
                onChange={ (e) => this.setState({email: e.target.value}) }>
              </Input>

              <button className="primary w-100"
                disabled={ this.state.email.length === 0 || this.state.firstName.length === 0 || this.state.lastName.length === 0}
                onClick={ () => this.inviteUser({
                  first_name: this.state.firstName,
                  last_name: this.state.lastName,
                  email: this.state.email,
                  role_id: this.state.role_id
                }) }>
                Invite User
              </button>
            </form>
          </Modal>
        ) : null}

        {(this.state.showConfirmModal) ? (
          <Modal toggleModal={ this.toggleDeleteUserModal }
            header="Are you sure you want to delete this user?"
            subheader="This action will permanently delete the selected user, and remove all of their associated content.">
            <div className="my1">
              <p>
                <strong className="t-mr1">{ this.state.selectedUserObj.first_name + ' ' + this.state.selectedUserObj.last_name }</strong>
                ({ (this.state.selectedUserObj.is_admin) ? 'Administrator' : 'Standard User' })
              </p>
              <p>{ this.state.selectedUserObj.email }</p>
            </div>
            <div className="flex flex-ac flex-jfe w-100 my1">
              <button className="primary inverted mr1" onClick={ () => this.toggleDeleteUserModal() }>Cancel</button>
              <button className="primary" onClick={ () => this.toggleDeleteUserModal(true) }>Delete User</button>
            </div>
          </Modal>
        ) : null}

        {(this.state.toastMessage) ? (
          <ToastNotification type={ this.state.toastMessage.type } message={ this.state.toastMessage.message } onClick={ () => this.displayToastMessage(null) }></ToastNotification>
        ) : null}
      </PageWrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.login.currentUser
  };
}

export default connect(mapStateToProps)(Team);
