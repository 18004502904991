import React, { Component } from 'react';
import PageWrapper from '../components/PageWrapper';
import {
  ResponsiveContainer, PieChart, Pie, Sector,
} from 'recharts';
import axios from 'axios';

import { API_ROOT } from '../apiConfig';

const renderActiveShapeCompany = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, percent, value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value} Courses`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const renderActiveShapeModule = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, percent, value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value} uses`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

export default class Landing extends Component {

  constructor(props) {
    super(props);

    this.getAllData = this.getAllData.bind(this);
    this.onCompanyPieEnter = this.onCompanyPieEnter.bind(this);
    this.onModulePieEnter = this.onModulePieEnter.bind(this);
    
    this.state = this.getInitialState();

    var _this = this;
    setTimeout ( function () { _this.getAllData() }, 250 );
  }

  getInitialState() {
    const initialState = { 
      loading : false,

      activeCompanyIndex: 0,
      activeModuleIndex: 0,

      modulesData: [],
      clientsData: []
    };

    return initialState;
  }

  getAllData () { 
  
    axios.get(`${API_ROOT}/db/stats/clients`, {

    }).then((resp) => {
      this.setState({ clientsData : resp.data });
    });

    axios.get(`${API_ROOT}/db/stats/modules`, {

    }).then((resp) => {
      this.setState({ modulesData : resp.data });
    });
  }

  onCompanyPieEnter = (data, index) => {
    this.setState({
      activeCompanyIndex: index,
    });
  }

  onModulePieEnter = (data, index) => {
    this.setState({
      activeModuleIndex: index,
    });
  }

  render() {

    return (
      <PageWrapper headerBar={ true } search={ true } sideMenu={ true }>
        <h2 className="mt0">Course Content Administration</h2>

        <div className="flex flex-wrap">
          <div className="child w-48 mr1 mb1 content-card">
            <div className="content-body">
              {(this.state.modulesData.length > 0) ? 
              <div>
                <h5 className="mb1 mt0">Most used modules</h5>
                <ResponsiveContainer width="100%" height={250}>
                  <PieChart>
                    <Pie
                      activeIndex={this.state.activeModuleIndex}
                      activeShape={renderActiveShapeModule}
                      data={this.state.modulesData}
                      dataKey="value"
                      innerRadius={60}
                      outerRadius={80}
                      fill="#8884d8"
                      onMouseEnter={this.onModulePieEnter}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
              : null }
            </div>
          </div>
          <div className="child w-48 mr1 mb1 content-card">
            <div className="content-body">
              {(this.state.clientsData.length > 0) ?
              <div>
                <h5 className="mb1 mt0">Most frequent customers</h5> 
                <ResponsiveContainer width="100%" height={250}>
                  <PieChart>
                    <Pie
                      activeIndex={this.state.activeCompanyIndex}
                      activeShape={renderActiveShapeCompany}
                      data={this.state.clientsData}
                      dataKey="value"
                      innerRadius={60}
                      outerRadius={80}
                      fill="#8884d8"
                      onMouseEnter={this.onCompanyPieEnter}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
              : null }
            </div>
          </div>
          <div className="child w-48 mr1 mb1 content-card">
            <div className="content-body">
              <div>
                <h5 className="mb1 mt0">Courses by month</h5>
                <p>Display a graph showing how many courses are booked per month</p>
              </div>
            </div>
          </div>
          <div className="child w-48 mr1 mb1 content-card">
            <div className="content-body">
              <div>
                <h5 className="mb1 mt0">??</h5>
                <p>Not sure of the 4th graph here yet. Thoughts welcome.</p>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    )
  }
}
