import axios from 'axios';
import { LOGIN, USER } from '../apiConfig';

export const SET_LOGIN_PENDING = 'SET_LOGIN_PENDING';
export const SET_LOGIN_SUCCESS = 'SET_LOGIN_SUCCESS';
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR';
export const SET_AUTH_HEADERS = 'SET_AUTH_HEADERS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

function setLoginPending(isLoginPending) {
    return {
        type: SET_LOGIN_PENDING,
        isLoginPending
    };
}

function setLoginSuccess(isLoginSuccess) {
    return {
        type: SET_LOGIN_SUCCESS,
        isLoginSuccess
    };
}

function setLoginError(loginError) {
    return {
        type: SET_LOGIN_ERROR,
        loginError
    };
}

function setAuthHeaders(userId, authToken) {
    axios.defaults.headers.common['Authorization'] = 'bearer ' + authToken;
    axios.defaults.headers.get['Authorization'] = 'bearer ' + authToken;

    return {
        type: SET_AUTH_HEADERS,
        userId,
        authToken
    };
}

export function setCurrentUser(currentUser) {
    return {
        type: SET_CURRENT_USER,
        currentUser
    };
}

function callLoginApi(email, password, callback) {
    setTimeout(() => {
        axios.post(LOGIN, {
            email,
            password
        }).then((data) => {
            return callback(data);
        }).catch((err) => {
            return callback(err);
        });
    });
}

function getCurrentUser(userId, token, callback) {
    setTimeout(() => {
        axios.get(`${USER}/` + userId, {
            headers: {Authorization: 'Bearer ' + token}
        }).then((data) => {
            return callback(data);
        }).catch((err) => {
            return callback(err);
        });
    });
}

export function hasPermission(user, permission) {
    return user.roles.permissions.find(p => p.name === permission)
}

export function login(email, password) {
    return (dispatch) => {
        dispatch(setLoginPending(true));
        dispatch(setLoginSuccess(false));
        dispatch(setLoginError(null));

        callLoginApi(email, password, (resp) => {
            dispatch(setLoginPending(false));

            if (resp.status === 200) {
                dispatch(setAuthHeaders(resp.data.id, resp.data.token));

                getCurrentUser(resp.data.id, resp.data.token, (resp) => {
                    if (resp.data) {
                        dispatch(setCurrentUser(resp.data));
                        dispatch(setLoginSuccess(true));
                    } else {
                        dispatch(setLoginError(new Error('Error logging in')));
                    }
                });

            } else {
                dispatch(setLoginError(new Error('Error logging in')));
            }
        });
    };
}

export function logout() {
    return (dispatch) => {
        dispatch(setLoginSuccess(false));
        dispatch(setAuthHeaders(null, null));
    };
}
