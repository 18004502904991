export const SET_DARK_MODE_STATE = 'SET_DARK_MODE_STATE';
export const TOGGLE_SIDE_MENU = 'TOGGLE_SIDE_MENU';
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';

export function setDarkModeState(darkModeState) {
  return {
    type: SET_DARK_MODE_STATE
  };
}

export function toggleSideMenu(sideMenuState) {
  return {
    type: TOGGLE_SIDE_MENU
  };
}

export function setSearchValue(searchValue) {
  return {
    type: SET_SEARCH_VALUE,
    searchValue
  };
}
