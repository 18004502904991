import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import classNames from 'classnames';
import { toggleSideMenu, setDarkModeState, setSearchValue } from '../actions/actions';
import { logout } from '../actions/auth';

import CCLogoLight from '../images/cc-logo@2x.png';
import CCLogoDark from '../images/Carter_Corson_Master_Logo-CentralStack.png';

import CCLogoSmLight from '../images/cc-logo-sm-light@2x.png';
import CCLogoSmDark from '../images/Carter_Corson_Master_Logo-Icon.png';

import { SideMenu, HeaderBar, SecondaryBar } from 'filament-ui';

class PageWrapper extends Component {
  constructor(props) {
    super(props);

    this.updateDimensions = this.updateDimensions.bind(this);
    this.callToggleSideMenu = this.callToggleSideMenu.bind(this);
    this.callDarkModeState = this.callDarkModeState.bind(this);
    this.callLogout = this.callLogout.bind(this);
    this.redirectRoute = this.redirectRoute.bind(this);
    this.searchCallback = this.searchCallback.bind(this);

    this.state = {
      width: window.innerWidth,
      userProfile: null,
      redirectAllowed: false,
      redirectRoute: ''
    };
  }

  componentWillMount() {
    const { authToken } = this.props;

		if (authToken) {
			axios.defaults.headers.common['Authorization'] = 'bearer ' + authToken;
      axios.defaults.headers.get['Authorization'] = 'bearer ' + authToken;
		}

		this.setCurrentUserProfile();
    this.updateDimensions();
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  setCurrentUserProfile() {
		const { currentUser } = this.props;
    let { userProfile } = this.state;
    
		if (currentUser) {
			userProfile = {
				name: `${currentUser.first_name} ${currentUser.last_name}`,
				location: currentUser.location,
        email: currentUser.email,
        role: currentUser.roles.name
			};
		}

		this.setState({
			userProfile
		});
	}

  searchCallback(returnedValue) {
    this.props.setSearchValue(returnedValue);
  }

  callToggleSideMenu() {
    this.props.toggleSideMenu();
  }

  callDarkModeState() {
    this.props.setDarkModeState();
  }

  updateDimensions() {
    if (window.innerWidth < 1025 && this.props.sideMenuState) {
      this.callToggleSideMenu();
    }

    this.setState({width: window.innerWidth});

    this.forceUpdate();
  }

  callLogout() {
    this.props.logout();
  }

  redirectRoute(route) {
    if (window.location.href.indexOf(route.route) === -1) {
      this.setState({redirectAllowed: true, redirectRoute: route.route});
    }
  }

  render() {
    const menuLinks = [{
    //   name: 'Home',
    //   route: '/landing',
    //   icon: 'home'
    // }, {
      name: 'Clients',
      route: '/clients',
      icon: 'briefcase'
    }, {
      name: 'Course Content',
      route: '/content',
      icon: 'books'
    }];

    const profileOptions = [[{
      name: 'Profile',
      icon: 'user',
      action: this.redirectRoute,
      route: '/profile'
    }, {
      icon: 'users',
      name: 'Team',
      action: this.redirectRoute,
      route: '/team'
    }, {
      name: 'Types',
      action: this.redirectRoute,
      route: '/types'
    }], [{
      icon: 'sign-out-alt',
      name: 'Logout',
      action: this.callLogout
    }]];

    let { headerBar, search, sideMenu, darkModeState, sideMenuState, subHeader } = this.props;
    let wrapperClasses = classNames('wrapper content', { 'inverted-colours': darkModeState, 'content-float': sideMenuState && this.state.width >= 1025, 'content-small': !sideMenuState && this.state.width < 1025 });

    if (search) {
      search = {
        value: this.props.searchValue,
        action: this.searchCallback
      };
    }

    if (this.state.redirectAllowed) {
      return <Redirect to={ this.state.redirectRoute } />;
    }

    return (
      <div className="page-wrapper">
        {((sideMenu) ? (
          <SideMenu toggleAction={ this.callToggleSideMenu }
            darkModeAction={ this.callDarkModeState }
            sideMenuState={ sideMenuState }
            darkModeState={ darkModeState }
            windowWidth={ this.state.width }
            logoLight={ CCLogoLight }
            logoDark={ CCLogoDark }
            menuLinks={ menuLinks }
            listItems={ [] }
            rootLink={ '/clients' }>
          </SideMenu>
        ) : null)}
        <div className={ wrapperClasses }>
          {((headerBar) ? (
            <HeaderBar toggleAction={ this.callToggleSideMenu }
              search={ { } }
              hasSecondary={ (subHeader) ? true : false }
              windowWidth={ this.state.width }
              logoLight={ CCLogoSmLight }
              logoDark={ CCLogoSmDark }
              profile={ this.state.userProfile }
              profileOptions={ profileOptions }>
            </HeaderBar>
          ) : null )}
          {((subHeader) ? <SecondaryBar subHeader={ subHeader }></SecondaryBar> : null)}

          <div className={"pt1 mb3" + (this.state.width < 1025 ? ' px1' : ' px2')}>
            { this.props.children }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    darkModeState: state.setDarkModeState.darkModeState,
    sideMenuState: state.toggleSideMenu.sideMenuState,
    searchValue: state.setSearchValue.searchValue,
    currentUser: state.login.currentUser,
    authToken: state.login.authToken
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDarkModeState: (darkModeState) => dispatch(setDarkModeState(darkModeState)),
    toggleSideMenu: (sideMenuState) => dispatch(toggleSideMenu(sideMenuState)),
    setSearchValue: (searchValue) => dispatch(setSearchValue(searchValue)),
    logout: () => dispatch(logout())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PageWrapper);
