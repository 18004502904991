import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Input, ToastNotification } from 'filament-ui';
import axios from 'axios';
import { RESET_PASSWORD } from '../apiConfig';

export default class ResetPassword extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      showError: false,
      toastMessage: null
    };

    this.displayToastMessage = this.displayToastMessage.bind(this);
  }

  submitForm(event) {
    event.preventDefault();

    const payload = {
      email: this.state.email
    };

    axios.post(RESET_PASSWORD, payload).then((resp) => {
      console.log(resp);
      if (resp.status === 200) {
        this.displayToastMessage({type: 'success', message: 'We have sent you an email with instructions on how to reset your password.'});
      }
    }).catch((err) => {
      this.displayToastMessage({type: 'error', message: err.message});
    });
  }

  checkEmail(e) {
    this.setState({email: e.target.value});

    const isEmail = this.validateEmail(e.target.value);

    if (!isEmail) {
      this.setState({showError: true});
    } else {
      this.setState({showError: false});
    }
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  displayToastMessage(message) {
    this.setState({toastMessage: message});
  }

  render() {
    let { email, showError } = this.state;

    return (
      <div className="page-height bg-white flex-centred w-100">
        <header className="w-55 w-85-tab">
          <Link to="/">← Back to Login</Link>
          <h4 className="mt3">Reset Password</h4>
          <p className="accent-dark small">
            Enter your email to receive an email with instructions on how to reset your password.
          </p>

          <form className="my2">
            <Input type="email"
              placeholder="Enter your email address..."
              inputId="emailAddress"
              label="Email"
              error={ showError }
              errorMessage="Please make sure you enter a valid email address."
              value={ email }
              onChange={ (e) => this.checkEmail(e) }>
            </Input>

            <button className="primary w-100 t-mt2"
              disabled={ email.length === 0  || showError}
              onClick={ (e) => this.submitForm(e) }>
              Reset
            </button>
          </form>
        </header>

        {(this.state.toastMessage) ? (
          <ToastNotification type={ this.state.toastMessage.type } message={ this.state.toastMessage.message } onClick={ () => this.displayToastMessage(null) }></ToastNotification>
        ) : null}
      </div>
    )
  }
}
