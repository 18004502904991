import { combineReducers } from 'redux';

import {
  SET_DARK_MODE_STATE,
  TOGGLE_SIDE_MENU,
  SET_SEARCH_VALUE
} from '../actions/actions';

import {
  SET_LOGIN_PENDING,
  SET_LOGIN_SUCCESS,
  SET_LOGIN_ERROR,
  SET_AUTH_HEADERS,
  SET_CURRENT_USER
} from '../actions/auth';

function setDarkModeState(state = {}, action) {
  switch (action.type) {
    case SET_DARK_MODE_STATE:
      return { ...state, darkModeState: !state.darkModeState };
    default:
      return state;
  }
}

function toggleSideMenu(state = {}, action) {
  switch (action.type) {
    case TOGGLE_SIDE_MENU:
      return { ...state, sideMenuState: !state.sideMenuState };
    default:
      return state;
  }
}

function setSearchValue(state = {}, action) {
  switch (action.type) {
    case SET_SEARCH_VALUE:
      return { ...state, searchValue: action.searchValue };
    default:
      return state;
  }
}

function login(state = {
  isLoginSuccess: false,
  isLoginPending: false,
  loginError: null,
  userId: null,
  authToken: null,
  currentUser: null
}, action) {
  switch (action.type) {
    case SET_LOGIN_PENDING:
      return { ...state, isLoginPending: action.isLoginPending };

    case SET_LOGIN_SUCCESS:
    return { ...state, isLoginSuccess: action.isLoginSuccess };

    case SET_LOGIN_ERROR:
      return { ...state, loginError: action.loginError };

    case SET_AUTH_HEADERS:
      return { ...state, userId: action.userId, authToken: action.authToken };

    case SET_CURRENT_USER:
      return { ...state, currentUser: action.currentUser };

    default:
      return state;
  }
}

const rootReducer = combineReducers({
  setDarkModeState,
  toggleSideMenu,
  setSearchValue,
  login
});

export default rootReducer;
