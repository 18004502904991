import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider, connect } from 'react-redux';
import configureStore from '../configureStore';
import { logout } from '../actions/auth';
import { loadState, saveState } from '../localStorage';
import routes from '../router';
import axios from 'axios';
import 'filament-ui/dist/index.css';
import '../index.css';

const persistedState = loadState();

const store = configureStore(
  persistedState
);

store.subscribe(() => {
  saveState(store.getState());
});

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoginSuccess: store.getState().login.isLoginSuccess
    };

    if ( this.state.isLoginSuccess ) { 
      axios.defaults.headers.common['Authorization'] = 'bearer ' + store.getState().login.authToken;
      axios.defaults.headers.get['Authorization'] = 'bearer ' + store.getState().login.authToken;
    }
  }

  componentWillMount() {
    store.subscribe(() => {
      this.setState({
        isLoginSuccess: store.getState().login.isLoginSuccess
      });
    });
  }

  render() {
    let { isLoginSuccess } = this.state;

    return (
      <Provider store={ store }>
        <Router>
          <Switch>
            {routes.map((route, index) => (
              ((route.private && isLoginSuccess) || (!route.private)) ? (
                <Route key={ index } exact={ route.exact } path={ route.path } component={ route.component } />
              ) : (
                <Redirect key={ index } to='/' />
              )
            ))}

            <Redirect from='*' to='/404' />
          </Switch>
        </Router>
      </Provider>
    )
  }
}

const UNAUTHORIZED = 401;
const ERROR = 400;
const { dispatch } = store;

axios.interceptors.response.use(
  response => response,
  error => {
    const { status } = error.response;
  
    if (status === UNAUTHORIZED) {
      dispatch(logout());
    }

    if (status === ERROR) {
      console.log("error : ", error );
    }
    
    return Promise.reject(error);
  }
);

const mapStateToProps = (state) => {
  return {
    isLoginSuccess: state.login.isLoginSuccess
  };
}

export default connect(mapStateToProps)(App);
