import React, { Component } from 'react';
import PageWrapper from '../components/PageWrapper';
import { Loader, SelectDropdown, Table, Checkbox, Modal, Icon, Input } from 'filament-ui';
import LoaderRing from '../images/loader.svg';
import axios from 'axios';

import { API_ROOT } from '../apiConfig';

export default class Types extends Component {

  constructor(props) {
    super(props);

    this.saveType = this.saveType.bind(this);
    this.checkBoxUpdated = this.checkBoxUpdated.bind(this);
    this.selectdUpdated = this.selectdUpdated.bind(this);
    this.editType = this.editType.bind(this);
    this.cancelModal = this.cancelModal.bind(this);
    this.getData = this.getData.bind(this);
    this.newType = this.newType.bind(this);
    this.deleteType = this.deleteType.bind(this);

    this.state = { 
      loading : true
    }
  }

  async componentDidMount () { 
    this.getData();
  }

  async getData () { 
    var wireframeTypes = await axios.get(API_ROOT + "/db/wireframe_types");
    var types = wireframeTypes.data.documents;

    var allModules = await axios.get(API_ROOT + "/db/modules");
    var modules = allModules.data.documents;

    var modList = modules.map ( (mod) => { 
      return { 
        name : mod.name,
        obj : mod
      }
    });

    modList.push ( { 
      name : "None",
      obj : { 
        id : null
      }
    });

    this.setState ( { 
      allTypes : types,
      allModules : modList,
      colourOptions : [ 
        {name : 'Blue', value : "primary" },
        {name : 'Light Blue', value : "secondary" },
        {name : 'Orange', value : "third" },
        {name : 'Purple', value : "forth" },
        {name : 'Red', value : "fifth" },
        {name : 'None', value : "" },
      ],
      loading: false
    })
  }

  async deleteType ( values ) { 
    if ( values.data.id ) { 
      await axios.delete(API_ROOT + "/db/wireframe_types/" + values.data.id ); // delete
    }
    this.setState ( { 
      showModal : false,
      selectedType : null
    }, () => { 
      this.getData(); // refresh things... 
    });
  }

  async saveType (  ) { 
    if ( this.state.selectedType.id ) { 

      var newValues = JSON.parse(JSON.stringify(this.state.selectedType));
      delete newValues.module;
      delete newValues.created_at;
      delete newValues.updated_at;

      await axios.put(API_ROOT + "/db/wireframe_types/" + this.state.selectedType.id, newValues ); // edit
    } else { 
      await axios.post(API_ROOT + "/db/wireframe_types", this.state.selectedType ); /// new 
    }
    this.setState ( { 
      showModal : false,
      selectedType : null
    }, () => { 
      this.getData(); // refresh things... 
    });
  }

  newType () { 
    this.setState ( { 
      showModal : true,
      selectedType : {}
    })
  }

  checkBoxUpdated ( e, property  ) { 
    var checked = e[0].checked;
    var tmp = this.state.selectedType;
    tmp[property] = ( checked ? 1 : 0  );
    this.setState ( { selectedType : tmp } );
  }

  selectdUpdated ( selected, property ) { 
    var newObj = { 
      ...this.state.selectedType,
    };
    if ( selected.obj ) { 
      newObj[property] = selected.obj.id;
    } else { 
      newObj[property] = selected.value;
    }
    
    var newState = { selectedType : newObj };
    this.setState ( newState );
  }

  editType ( values ) { 
    this.setState ( { 
      showModal : true,
      selectedType : values.data
    })
  }

  cancelModal ( ){ 
    this.setState ( { 
      showModal : false,
      selectedType : null
    })
  }

  render () { 

    const tableAction = [{
      icon: 'plus',
      name: 'Add Element Type',
      action: this.newType
    }];

    const headers = ['Name', 'In Agenda', 'Show Duration', 'Colour', 'Element'];
    const widths = [ 30, 10, 10, 10, 30 ];

    return (
      <PageWrapper headerBar={ true } search={ true } sideMenu={ true }>
      <h2 className="mt0">Wireframe Components</h2>

      {(this.state.loading) ? 
      
        <Loader type={ LoaderRing } size="small"></Loader> : 
        <Table
          headers={ headers }
          widths={ widths }
          action={ tableAction }
          data={ this.state.allTypes.map ( ( type ) => { 
            return [ 
              { 
                id : 1,
                value : type.name
              },
              { 
                id : 2,
                component : <Icon
                  className={ type.inAgenda === 1 ? 'primary' : 'fifth' }
                  size={ '2x' }
                  icon={ type.inAgenda === 1 ? 'check-square' : 'times-square' }
                  >
                </Icon>
              },
              { 
                id : 3,
                component : <Icon
                  className={ type.showDuration === 1 ? 'primary' : 'fifth' }
                  size={ '2x' }
                  icon={ type.showDuration === 1 ? 'check-square' : 'times-square' }
                  >
                </Icon>
              },
              { 
                id : 4,
                component : <Icon
                  className={ type.className || '' }
                  size={ '2x' }
                  type={ 'solid' }
                  icon={ 'square' }
                  >
                </Icon>
              },
              { 
                id : 5,
                value: ( type.module ? type.module.name : 'N/A' )
              }, { 
                id : 6,
                value: 'actions',
                actions: [ {
                  icon: 'edit',
                  name : "Edit",
                  data: type,
                  action: this.editType
                }, {
                  icon: 'trash',
                  name: "Delete",
                  data: type,
                  action: this.deleteType
                } ]
              }
            ] 
          }) }
          sortOptions={ {} }
          type="table"
          title="Course Wireframe Components">
        </Table>
      }

      {this.state.showModal ? (
          <Modal
            toggleModal={ this.cancelModal }
            header="Edit Wireframe Component"
            subheader="Set the values below to determine how the wireframe element is used."
            >

            <form className="w-100">
              <div className="flex mb1 w-100">
                <Input
                  label="Name"
                  className="w-100"
                  type="text"
                  placeholder="The name of this wireframe type"
                  inputId="basicInput"
                  value={ this.state.selectedType.name }
                  onChange={ (e) => this.setState( { selectedType: { ...this.state.selectedType, name : e.target.value } } ) }>
                </Input>
              </div>

              <div className="flex mb1">
                <Checkbox
                  noMargin={ true }
                  checkboxes={ [ { label: 'Include in Agenda?', checked: this.state.selectedType.inAgenda === 1 } ] }
                  callback={ (e) => this.checkBoxUpdated( e, "inAgenda" ) }>
                </Checkbox>
              </div>

              <div className="flex mb1">
                <Checkbox
                  noMargin={ true }
                  checkboxes={ [ { label: 'Show Duration?', checked: this.state.selectedType.showDuration === 1 } ] }
                  callback={ (e) => this.checkBoxUpdated( e, "showDuration" ) }>
                </Checkbox>
              </div>

              <SelectDropdown
                className="flex-grow"
                label="Colour"
                selectText="Select a colour"
                options={ this.state.colourOptions }
                callback={ (e) => this.selectdUpdated(e, "className" ) }>
              </SelectDropdown>

              <SelectDropdown
                className="flex-grow"
                label="Include the following element"
                selectText="Select an element"
                options={ this.state.allModules }
                default={ this.state.selectedType.mid }
                callback={ (e, row, index ) => this.selectdUpdated(e, "mid" ) }>
              </SelectDropdown>
            </form>

            <div className="w-100 flex flex-afe flex-jfe mt3">
              <button onClick={ () => this.cancelModal() } className="button primary inverted mr1">Cancel</button>
              <button className="button primary" onClick={ () => this.saveType(this.state.selectedType) }>Save</button>
            </div>
          </Modal>
        ) : null}
      </PageWrapper>
    )
  }
}