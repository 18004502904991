/*eslint no-undef: 0*/

import React, { Component } from 'react';
import { Redirect } from 'react-router';
import PageWrapper from '../components/PageWrapper';
import { Table, ToastNotification, Loader, ContentCard, Modal } from 'filament-ui';
import LoaderRing from '../images/loader.svg';
import axios from 'axios';

import { API_ROOT } from '../apiConfig';

export default class Client extends Component {

  constructor(props) {
    super(props);

    this.getClient = this.getClient.bind(this); 
    this.newCourse = this.newCourse.bind(this);
    this.viewSection = this.viewSection.bind(this);
    this.deleteCourse = this.deleteCourse.bind(this);
    this.doDeleteCourse = this.doDeleteCourse.bind(this);
    this.cancelModal = this.cancelModal.bind(this);
    this.downloadCourse = this.downloadCourse.bind(this);
    this.regenerateCourse = this.regenerateCourse.bind(this);
    this.editCourse = this.editCourse.bind(this);

    this.state = this.getInitialState();

    var _this = this;
    setTimeout ( function () { _this.getClient() }, 250 );
  }

  getInitialState() {
    const initialState = { 
      loading : true,
      showToast : false,

      client : {},
      courseData: [],

      subHeaderRoutes: {
        action: {
          text: 'New Course',
          icon: 'plus',
          action: this.newCourse
        },
        tabs: [

        ]
      }
    };

    return initialState;
  }

  viewSection(redirect) {
    this.setState({ redirect });
  }

  newCourse(  ) {
    this.viewSection("/client/" + this.props.match.params.id + "/course/new" ); // create a new course for this client...
  }

  cancelModal () { 
    this.setState ( { 
      showDeleteModal : false,
      moduleToDelete : null
    });
  }

  deleteCourse ( row, index, item ) { 
    var course = row.data;
    this.setState ( { 
      showDeleteModal : true,
      moduleToDelete : course
    });
  }

  doDeleteCourse (  ) {
    var course = this.state.moduleToDelete;
    return axios.delete(`${API_ROOT}/db/course/` + course.id, {
    }).then((resp) => {
      this.setState ( { 
        showDeleteModal: false,
        moduleToDelete: null
      }, () => { 
        this.getClient();
      })
    });
  }

  editCourse ( row, index, item ) {
    var course = row.data;
    this.viewSection("/client/" + this.props.match.params.id + "/course/" + course.id );
  }

  async regenerateCourse ( row, index, item ) { 

    console.log("regenerating", row );
    var course = row.data;
    this.setState ( { 
      showToast : true,
      toastState : "success",
      toastMessage : "Generating new version of the course.",
      loadingCourse : course.id
    });

    await this.getClient(); // redraw the table... 

    return axios.post(`${API_ROOT}/db/course/` + course.id + `/regenerate`, {
      client_id : course.client_id
    }).then((resp) => {

      console.log("after regen", resp.data);

      this.setState ( { 
        showToast: false,
        toastState : "success"
      }, () => { 
        this.setState ( { 
          showToast : true,
          toastState : resp.data.error ? "error" : "success",
          toastMessage : resp.data.error ? `Unable to regenerate document. Please check files. ${resp.data.error}` : "Document Successfully regenerated.", 
          loadingCourse : null
        }, () => { 
          setTimeout ( () => { 
            this.getClient(); /// refresh the menus / data in the menus.
          }, 1000 );
        });
      } );
    });
  }

  downloadCourse ( row, index, item ) { 
    console.log("downloading course", row);
    var course = row.data;
    axios.get(`${API_ROOT}/db/files/` + course.file_id, {
      responseType: 'blob'
    }).then((resp) => {
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', course.name + ".docx");
      document.body.appendChild(link);
      link.click();
    });
  }

  downloadAgenda ( row, index, item ) { 
    var course = row.data;
    axios.get(`${API_ROOT}/db/files/` + course.agenda_file_id, {
      responseType: 'blob'
    }).then((resp) => {
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', course.name + " Agenda.docx");
      document.body.appendChild(link);
      link.click();
    });
  }

  getClient () { 
    console.log("getting client...");
    var _this = this;
    var clientId = this.props.match.params.id;
    return axios.get(`${API_ROOT}/db/clients/` + clientId + "?ts=" + new Date().getTime(), {
    }).then((resp) => {
      var rows = [];

      var client = resp.data;

      console.log("client = ", client);

      for ( var i = 0; i < client.courses.length; i++ ) {
        var course = client.courses[i];
        var mods2 = course.wireframes.map( ( w ) => { 
          if ( w.wireframe_type.id === 5 ) { 
            return w.module.name;
          } else { 
            return w.wireframe_type.name;
          }
        });

        var row = [ ];
        row.push( { 
          id : 1,
          value : course.name,
          sub: "Elements : " + mods2.join(", ")
        } );
        row.push( { 
          id : 2,
          value : new Date(course.start_date).toDateString()
        } );

        row.push( { 
          id : 3,
          component: ( this.state.loadingCourse != null && this.state.loadingCourse === course.id ? <img 
            style={ { 
              marginTop: '5px', 
              height: '30px'
            }}
            alt=""
            src={ LoaderRing }></img> : null )
        } );

        row.push ( {
          id: 4,
          value: 'actions',
          actions: [
            {
              icon: 'edit',
              name : "Edit",
              data: course,
              action: _this.editCourse
            }, {
              icon: 'download',
              name : "Agenda",
              data: course,
              disabled: !course.agenda_file_id,
              action: _this.downloadAgenda
            }, {
              icon: 'download',
              name : "Download",
              data: course,
              disabled: !course.file_id,
              action: _this.downloadCourse
            }, {
              icon: 'repeat',
              name : "Regenerate",
              data: course,
              action: _this.regenerateCourse
            }, {
              icon: 'trash',
              name : "Delete",
              data: course,
              action: _this.deleteCourse
            }
          ]
        } );

        rows.push ( row );
      }

      _this.setState( { client, courseData: rows, loading : false } );
    });
  }

  render() {
    let { subHeaderRoutes, redirect, client } = this.state;

    const sortOptionsSelect = {
      col: 0,
      sort: 'desc'
    };

    const tableHeaders = [ "Name", "Workshop Date", "", "" ];

    if (redirect) {
      return <Redirect push to={redirect} />;
    }

    return (
      <PageWrapper headerBar={ true } search={ true } sideMenu={ true } subHeader={ subHeaderRoutes }>
        <h2 className="mt0"><a href={ client.website } >{ client.name }</a></h2>
        <p>{ client.description }</p>

        {(this.state.loading) ? <Loader type={ LoaderRing } size="small"></Loader> : null}

        {(!this.state.loading) ? (
          <div>
          {this.state.courseData.length > 0 ? (
            <Table
              headers={ tableHeaders }
              widths={ [ 70,15,10,5 ] }
              data={ this.state.courseData }
              sortOptions={ sortOptionsSelect }
              type="table">
            </Table>
          ) : (
            <ContentCard centred={ true }>
              <div className="flex flex-ac flex-col">
                <h5>No Courses</h5>
                <p>There are no courses currently in the system for this client.</p>
              </div>
            </ContentCard>
          )}
          </div>
        ): null }

        { this.state.showDeleteModal ?
          <Modal
            toggleModal={ this.cancelModal }
            header="Delete Course"
            headerIcon={ 'trash' }
            subheader="Are you sure?"
            actions={ [
              {name: 'Confirm', class: 'primary', action: () => this.doDeleteCourse()},
              {name: 'Cancel', class: 'primary inverted', action: () => this.cancelModal()}
            ] }
            fitModal={ true }>
              <p>Deleting this course will remove the wireframe and any the generated file associated with this course. There is no way to undo this. Are you sure you want to do that?</p>
          </Modal>
          : null }

        {(this.state.showToast) ? (
          <ToastNotification 
            type={ this.state.toastState ? this.state.toastState : "success" }
            message={ this.state.toastMessage } 
            onClick={ () => this.setState({showToast: false, toastMessage: null}) }></ToastNotification>
        ) : null}

      </PageWrapper>
    )
  }
}
