let backendHost;

const hostname = process.env.NODE_ENV;

if (hostname === 'development') {
    backendHost = 'http://localhost:4000';
} else { 
    backendHost = window.location.protocol + "//" + window.location.host;
}


export const API_ROOT = `${backendHost}/api`;

// Auth endpoints
export const LOGIN = `${API_ROOT}/auth/login`;
export const INVITE_REGISTER = `${API_ROOT}/auth/register`;
export const INVITE_USER = `${API_ROOT}/auth/invite`;
export const RESET_PASSWORD = `${API_ROOT}/auth/reset-password`;

// Users endpoints
export const USER = `${API_ROOT}/users`;
