import React, { Component } from 'react';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import DatePicker from 'react-date-picker';
import TimePicker from 'react-time-picker';

export default class CustomDateTimePicker extends Component {

  constructor(props) {
    super(props);

    this.state = {
      date: new Date(),
      dates: []
    };

    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeDates = this.onChangeDates.bind(this);
  }

  componentDidMount() {
    this.setState({
      date: this.props.date,
      dates: this.props.dates
    });
  }

  onChangeDates(dates) {
    let { callback } = this.props;

    this.setState({dates});

    if (callback) {
      callback(dates);
    }
  }

  onChangeDate(date) {
    let { callback } = this.props;

    this.setState({date});

    if (callback) {
      callback(date);
    }
  }

  render() {
    let { date, dates } = this.state;
    let { label, className, time, range } = this.props;

    return (
      <div className={"input-group my1 " + className}>
        {(label) ? <label style={ {display: 'block'} }>{ label }</label> : null}

        {time ? (
          <div>
            {range ? (
              // Date & Time Range Picker
              <DateTimeRangePicker
                onChange={ this.onChangeDates }
                value={ dates }
                locale={ 'en-GB' }
              />
            ) : (
              // Date & Time Picker
              <TimePicker
                onChange={ this.onChangeDate }
                value={ date }
                locale={ 'en-GB' }
              />
            )}
          </div>
        ) : (
          <div>
            {range ? (
              // Date Range Picker
              <DateRangePicker
                onChange={ this.onChangeDates }
                value={ dates }
                locale={ 'en-GB' }
              />
            ) : (
              // Date Picker
              <DatePicker
                onChange={ this.onChangeDate }
                value={ date }
                locale={ 'en-GB' }
              />
            )}
          </div>
        )}
      </div>
    )
  }
}
